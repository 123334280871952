import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

const useInfoDrawer = () => {
  const history = useHistory();

  const onCardClickAction = useCallback(
    (val: string) => () => {
      history.push(val);
    },
    [history],
  );

  return { onCardClickAction };
};

export default useInfoDrawer;
