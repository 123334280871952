import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import useAwardCard from '@/components/ui/cards/AwardCard/useAwardCard';
import { AwardStatus } from '@/enums';
import { combineSx } from '@/utils';

import AwardWinnerCard from '../AwardWinnerCard';

import { list } from './constants';
import { styles } from './styles';

import type { IAwardResponse } from '@/dto';

const AwardDetailCard = ({ award }: { award: IAwardResponse }) => {
  const { t } = useTranslation();
  const { isConcluded, winner } = useAwardCard(award);
  const history = useHistory();

  const questions = useMemo(
    () =>
      Array.from({ length: 10 }, (_, index) => award?.[`description${index + 1}Label`]).filter(
        (item) => item,
      ),
    [award],
  );

  const onEditButtonClick = useCallback(() => {
    history.push('/purchasing-request/' + award.id + '/edit');
  }, [history, award.id]);

  return (
    <Card>
      <CardContent sx={styles.content}>
        <Box sx={styles.header}>
          <Box sx={styles.titleBox}>
            <Avatar>
              <StarIcon />
            </Avatar>
            <Typography variant="h5" sx={{ marginLeft: 1 }}>
              {award.name}
            </Typography>
          </Box>

          {award.status == AwardStatus.DRAFT && (
            <Button
              sx={styles.edit}
              onClick={onEditButtonClick}
              variant="contained"
              endIcon={<EditIcon fontSize="small" />}
              color="inherit"
            >
              Edit Purchasing Request
            </Button>
          )}
          <Box sx={styles.badge}>{award.status.replace('_', ' ')}</Box>
        </Box>
        <Box display="flex" flexWrap="wrap" flexDirection="column" pt={2}>
          <Box>
            <List disablePadding sx={{ columns: { xs: 1, sm: 2 } }}>
              {list().map(({ label, rowSx, key, format }) => (
                <ListItem key={`${label}_${key}`} disablePadding>
                  <Box sx={combineSx({ mt: 0.5 }, rowSx)}>
                    <Typography whiteSpace="nowrap" component="span" sx={styles.listLabel}>
                      {t(label)}:
                    </Typography>
                    <Typography component="span">{format?.(award) ?? award[key]}</Typography>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box mt={3}>
            <Typography whiteSpace="nowrap" component="span" sx={styles.listLabel}>
              Product Description:
            </Typography>
            <Typography component="span">{award.benchmarkGood}</Typography>
          </Box>
          <Box mt={3}>
            <Typography whiteSpace="nowrap" component="span" sx={styles.listLabel}>
              Questions:
            </Typography>
            <List disablePadding sx={{ listStyle: 'disc', pl: 2 }}>
              {questions.map((question, index) => (
                <ListItem
                  sx={{ display: 'list-item', pl: 1, pt: 0, fontWeight: 'bold' }}
                  key={index}
                >
                  {question}
                </ListItem>
              ))}
            </List>
          </Box>
          {isConcluded && <AwardWinnerCard mt={2} candidacy={winner} />}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AwardDetailCard;
