import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { RelatedProductsTable } from '@/components';

import type { ITabProps } from '../types';

const RelatedProductsTab = ({ candidacy }: ITabProps) => {
  const { t } = useTranslation();

  // const { relatedGoods, loading, selected } = useSelector(goodsState);
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getRelatedProducts({ companies: [{ id: candidacy.Company.id }] }));
  // }, [candidacy.Company.id, dispatch]);

  // useEffect(() => {
  //   dispatch(setSelected(null));
  // }, [dispatch]);

  // if (loading) {
  //   return <PageProgress />;
  // }

  return (
    <Box display="flex" justifyContent="center" flexDirection="column">
      {/* {!selected ? ( */}
      <Typography component="h4" variant="h4" textAlign="center" mb={3}>
        {t('Related Products')}
      </Typography>
      <RelatedProductsTable goods={candidacy.goods ?? []} />
      {/* ) : (
        <DetailedProduct product={selected} />
      )} */}
    </Box>
  );
};

export default RelatedProductsTab;
