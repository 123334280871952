export const styles = {
  title: {
    whiteSpace: 'initial',
    textAlign: 'center',
    fontWeight: 500,
  },
  infoContainer: {
    display: 'flex',
    gap: 1,
    flexDirection: 'column',
    mt: 3,
  },
  img: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'contain',
    my: 2,
  },
} as const;
