import { logout } from '@food/auth';
import LogoutIcon from '@mui/icons-material/Logout';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import type { MouseEvent } from 'react';

const useUserPopup = () => {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const history = useHistory();

  const logOutAction = useCallback(() => {
    logout();
    history.push('/login?traditional=true');
  }, [history]);

  const settings = useMemo(
    () => [{ text: 'Logout', action: logOutAction, Icon: LogoutIcon }],
    [logOutAction],
  );

  const handleOpenMenu = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchor(event.currentTarget);
  }, []);

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return { anchor, settings, handleOpenMenu, handleCloseMenu };
};

export default useUserPopup;
