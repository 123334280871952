import { gql } from '@apollo/client';

import { MediaType } from '@/enums';
import { MediaBaseFragment } from '@/qraphql';
import { client } from '@/utils';

import type { IEntityResponse, IMediaResponse } from '@/dto';

const createMedia = async (values: { file: File }) => {
  return client.mutate<IEntityResponse<IMediaResponse>>({
    mutation: gql`
      mutation MediaCreateMutation($values: MediaCreateInput!) {
        entity: mediaCreate(values: $values) {
          ...MediaBaseFragment
        }
      }
      ${MediaBaseFragment}
    `,
    variables: { values: { ...values, type: MediaType.LOGO } },
  });
};

const mediaService = {
  createMedia,
};

export default mediaService;
