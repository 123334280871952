const parse = (val: string) => {
  const splitted = val.replace('*|', '|').split('|');
  return {
    title: splitted[0],
    helperText: splitted[1] || '',
    isRequired: val.includes('*|'),
  };
};

const descriptionUtil = {
  parse,
};

export default descriptionUtil;
