import { useCallback } from 'react';

import type { INoteProps, INoteRequest } from './types';
import type { FormikHelpers } from 'formik/dist/types';

const useNoteForm = ({ onSubmit }: Pick<INoteProps, 'onSubmit'>) => {
  const handlerSubmit = useCallback(
    async (values: INoteRequest, helpers: FormikHelpers<INoteRequest>) => {
      await onSubmit(values, helpers);
    },
    [onSubmit],
  );
  return { handlerSubmit };
};

export default useNoteForm;
