import { Box } from '@mui/material';

import { DrawerController } from '@/components/ui/drawers';
import { DrawerType } from '@/enums';
import { combineSx } from '@/utils';

import type { IPageContainerProps } from './types';

const PageContainer = ({
  drawerType = DrawerType.Info,
  children,
  sx,
  ...props
}: IPageContainerProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <Box sx={combineSx({ py: 5, px: 3, flexGrow: 1 }, sx)} {...props}>
        {children}
      </Box>
      <DrawerController type={drawerType} />
    </Box>
  );
};

export default PageContainer;
