import { theme } from '@/constants/theme';

export const styles = {
  appBar: {
    zIndex: theme.zIndex.drawer + 2,
    backgroundColor: 'white',
  },
  toolbar: {
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    height: theme.spacing(8.5),
    width: theme.spacing(15),
  },
} as const;
