import { theme } from '@/constants/theme';

export const styles = {
  checkbox: {
    border: `1px solid #0000003b`,
    '&:not(:last-of-type)': {
      mb: 1,
    },
    mx: 0,
    borderRadius: theme.spacing(0.5),
    '&:hover': {
      borderColor: '#000000de',
    },
  },
} as const;
