import { Box, Typography } from '@mui/material';
import { useCallback } from 'react';

import { ImageLoader } from '@/components/ui/pdfs';

import type { IEditorialSectionResponse } from '@/dto';
import type { ImgHTMLAttributes } from 'react';

const CategoryElement = ({
  editorial,
  shouldTrackImage,
}: {
  editorial: IEditorialSectionResponse[];
  shouldTrackImage: boolean;
}) => {
  const ImageComponent = useCallback(
    (props: ImgHTMLAttributes<HTMLImageElement>) =>
      !shouldTrackImage ? <Box component="img" {...props} /> : <ImageLoader {...props} />,
    [shouldTrackImage],
  );

  return (
    <Box display="flex" flexWrap="wrap" gap={2} component="span">
      {editorial.map((item) => (
        <Box key={item.name} display="flex" gap={1} alignItems="center" component="span">
          {item.iconMedia?.smallThumbUrl && (
            <ImageComponent
              width={32}
              height={32}
              src={item.iconMedia.smallThumbUrl}
              alt={item.iconMedia.name}
            />
          )}
          <Typography variant="body2">{item.name}</Typography>
        </Box>
      ))}
    </Box>
  );
};

export default CategoryElement;
