import ApartmentIcon from '@mui/icons-material/Apartment';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import WorkIcon from '@mui/icons-material/Work';

import { CompanyTab, PropositionTab, BrandTab, RelatedProductsTab, ActionsTab } from './components';
import { DetailCandidacyModalTab } from './types';

import type { ITab } from './types';

export const tabs: ITab[] = [
  {
    label: DetailCandidacyModalTab.Company,
    Icon: ApartmentIcon,
    Tab: CompanyTab,
  },
  {
    label: DetailCandidacyModalTab.Brand,
    Icon: WorkIcon,
    Tab: BrandTab,
  },
  {
    label: DetailCandidacyModalTab.Proposition,
    Icon: ShoppingCartIcon,
    Tab: PropositionTab,
  },
  {
    label: DetailCandidacyModalTab.RelatedProducts,
    Icon: UploadFileIcon,
    Tab: RelatedProductsTab,
  },
  {
    label: DetailCandidacyModalTab.Actions,
    Icon: PlayCircleIcon,
    Tab: ActionsTab,
  },
];
