import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { Form, Formik, Field } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@/components/ui/inputs';

import { scheme } from './scheme';
import useUpdateNumberForm from './useUpdateNumberForm';

import type { IUpdateNumberFormProps } from './types';

const UpdateNumberForm = ({ onSubmit, onCancel }: IUpdateNumberFormProps) => {
  const { initialValues, onSubmitAction } = useUpdateNumberForm({ onSubmit });
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmitAction} validationSchema={scheme}>
      {({ isSubmitting }) => (
        <Form>
          <Typography variant="h4" mx={2} textAlign="center">
            {t(!initialValues.id ? 'Create New Annual Report' : 'Update Annual Numbers')}
          </Typography>
          <Field
            as={TextInput}
            type="number"
            label={t('Year')}
            name="year"
            required
            disabled={true}
          />
          <Field as={TextInput} type="number" label={t('Revenue')} name="revenue" />
          <Field as={TextInput} type="number" label={t('Num Of Employees')} name="numEmployees" />

          <Box gap={2} mt={2} display="flex">
            <Button variant="outlined" onClick={onCancel} disabled={isSubmitting}>
              {t('Cancel')}
            </Button>
            <Button
              variant="contained"
              type="submit"
              disabled={isSubmitting}
              endIcon={isSubmitting && <CircularProgress size={20} />}
            >
              {t('Submit Changes')}
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateNumberForm;
