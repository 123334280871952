import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material';
import { useField } from 'formik';

import { combineSx } from '@/utils';

import { styles } from './styles';

import type { SelectProps } from '@mui/material';

const SelectInput = <T extends string | number>({
  options,
  fullWidth = true,
  variant = 'outlined',
  margin = 'dense',
  size = 'small',
  sx,
  helperText,
  defaultValue,
  ...props
}: {
  options: { value: T; label: string }[];
  helperText: string;
} & SelectProps<T>) => {
  const [field, meta] = useField(props.name);

  return (
    <FormControl margin={margin} fullWidth={fullWidth} sx={combineSx(styles.root, sx)}>
      <InputLabel id="listingFee" size="small">
        {props.label}
      </InputLabel>
      <Select defaultValue={defaultValue} variant={variant} size={size} {...field} {...props}>
        {options.map((item) => (
          <MenuItem key={item.label} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {!meta.error && !!helperText && <FormHelperText>{helperText}</FormHelperText>}
      {meta.error && meta.touched && (
        <FormHelperText sx={styles.error}>{meta.error}</FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectInput;
