export enum AwardAccessRole {
  ANONYMUS = 'ANONYMUS',
  INTERNAL = 'INTERNAL',
  PRODUCER = 'PRODUCER',
  BUYER = 'BUYER',
  BUYER_PL_ITALIA = 'BUYER_PL_ITALIA',
  FOODSERVICE = 'FOODSERVICE',
  SHOPWORKER = 'SHOPWORKER',
  SERVICE = 'SERVICE',
  CONSUMER = 'CONSUMER',
}
