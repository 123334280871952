import { useMemo } from 'react';

import { AwardStatus, CandidacyAwardFinalPosition } from '@/enums';

import type { IAwardResponse } from '@/dto';

const useAwardCard = (award: IAwardResponse) => {
  const isConcluded = useMemo(() => award.status === AwardStatus.CONCLUDED, [award]);

  const winner = useMemo(() => {
    return isConcluded
      ? award.candidacies?.find((item) => {
          return item.awardResults?.find(
            ({ finalPosition }) => finalPosition === CandidacyAwardFinalPosition.WINNER,
          );
        }) ?? null
      : null;
  }, [award, isConcluded]);
  return { winner, isConcluded };
};

export default useAwardCard;
