import { AuthContext, AuthRoutes } from '@food/auth';
import { Router, Route, Switch } from 'react-router-dom';

import { MainLayout, DefaultLayout } from '@/components/layouts';
import { authUtil, history, tFood } from '@/utils';

import { routes } from './constants';

const Routes = () => {
  return (
    <Router history={history}>
      <AuthContext>
        <AuthRoutes
          config={authUtil.authConfig}
          authManager={authUtil.authManager}
          t={tFood}
          onRedirect={authUtil.onRedirect}
          container={DefaultLayout}
        >
          <Route path="/">
            <MainLayout>
              <Switch>
                {routes.map((item) => (
                  <Route key={item.path} {...item} />
                ))}
              </Switch>
            </MainLayout>
          </Route>
        </AuthRoutes>
      </AuthContext>
    </Router>
  );
};

export default Routes;
