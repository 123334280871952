import Box from '@mui/material/Box';
import { PhotoProvider, PhotoView } from 'react-photo-view';

import noImage from '@/assets/images/no-image.png';

import type { IPhotoPreviewProps } from './types';

const PhotoPreview = ({ images, alt, width = 90, height = 60 }: IPhotoPreviewProps) => (
  <PhotoProvider>
    {images.map((src, index) => (
      <PhotoView key={index} src={src ?? noImage}>
        <Box
          sx={{
            cursor: 'pointer',
            objectFit: 'contain',
            display: index !== 0 ? 'none' : 'inline',
          }}
          component="img"
          width={width}
          height={height}
          alt={alt}
          src={src ?? noImage}
        />
      </PhotoView>
    ))}
    {!images.length && (
      <PhotoView src={noImage}>
        <Box
          sx={{ cursor: 'pointer' }}
          component="img"
          width={width}
          height={height}
          alt={alt}
          src={noImage}
        />
      </PhotoView>
    )}
  </PhotoProvider>
);

export default PhotoPreview;
