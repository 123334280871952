import type { IEditorialSectionResponse } from '@/dto';

const getEsParent = (e: IEditorialSectionResponse): IEditorialSectionResponse =>
  e.Parent ? getEsParent(e.Parent) : e;

const parse = (editorials: IEditorialSectionResponse[] = []): IEditorialSectionResponse[] =>
  editorials
    ?.filter(Boolean)
    ?.filter((e) => e?.editorialBrand === 'IFN')
    .map(getEsParent)
    .filter((es) => es.iconMedia)
    .reduce(
      (res, es: IEditorialSectionResponse) =>
        res.some((les) => les.id === es.id) ? res : [...res, es],
      [],
    ) || [];

const editorialUtil = {
  parse,
  getEsParent,
};

export default editorialUtil;
