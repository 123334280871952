import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { BackgroundImage } from '@/components';

import type { IAwardWinnerCardProps } from './types';

const AwardWinnerCard = ({ candidacy, ...rest }: IAwardWinnerCardProps) => {
  const { t } = useTranslation();
  return candidacy ? (
    <Box flexGrow={1} {...rest}>
      <Typography textAlign="center" variant="h5">
        {t('Winner')}
      </Typography>
      <Box display="flex" my={0.5} alignItems="center" justifyContent="center">
        <BackgroundImage src={candidacy.Company?.logoMedia?.origUrl} />
      </Box>
      <Typography textAlign="center">{candidacy.Company.name}</Typography>
    </Box>
  ) : null;
};

export default AwardWinnerCard;
