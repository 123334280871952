import { gql } from '@apollo/client';

import { client } from '@/utils';

import type { QuestionSetCreateInput } from '@/dto';

const createQuestion = async (values: QuestionSetCreateInput) => {
  return client.mutate({
    mutation: gql`
      mutation QuestionSetCreate($values: QuestionSetCreateInput!) {
        questionSetCreate(values: $values) {
          id
          sentDate
          question1
        }
      }
    `,
    variables: { values },
  });
};

const questionService = {
  createQuestion,
};

export default questionService;
