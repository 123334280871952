import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useModal } from '@/hooks';
import { usersRetailerState } from '@/redux/user/selectors';

const useCompanyDataDrawer = () => {
  const retailer = useSelector(usersRetailerState);
  const [openModal, handleOpenModal, handleCloseModal] = useModal();
  const [openLogoModal, handleOpenLogoModal, handleCloseLogoModal] = useModal();

  const annualData = useMemo(() => {
    const { id, year, revenue, numEmployees } =
      retailer?.companyYears?.reduce((a, b) => (a && a.year - b.year ? a : b), null) || {};

    if (!id) {
      return null;
    }

    return [
      { label: 'Year', value: year },
      { label: 'Revenue', value: revenue },
      { label: 'Employees', value: numEmployees },
    ];
  }, [retailer?.companyYears]);

  return {
    retailer,
    openModal,
    handleOpenModal,
    handleCloseModal,
    annualData,
    openLogoModal,
    handleOpenLogoModal,
    handleCloseLogoModal,
  };
};

export default useCompanyDataDrawer;
