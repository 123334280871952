import { typographyClasses, buttonClasses } from '@mui/material';

import { theme } from '@/constants/theme';

export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTop: `1px solid ${theme.palette.grey[200]}`,
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    px: 1,
  },
  button: (disable: boolean) => ({
    whiteSpace: 'nowrap',
    flexShrink: 0,
    textTransform: 'capitalize',
    py: 0.25,
    [theme.breakpoints.down('sm')]: {
      [`& .${buttonClasses.startIcon}`]: {
        mr: 0,
      },
      [`& .${buttonClasses.endIcon}`]: {
        ml: 0,
      },
    },
    [`& .${typographyClasses.root}`]: {
      display: {
        sm: 'inline-flex',
        xs: 'none',
      },
    },
    ...(disable
      ? {
          visibility: 'hidden',
          opacity: 0,
        }
      : {}),
  }),
  item: {
    display: {
      lg: 'flex',
      xs: 'none',
    },
    alignItems: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color: theme.palette.text.secondary,
  },
  itemActive: {
    display: 'flex',
    cursor: 'initial',
    fontWeight: 'bold',
  },
  list: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    fontWeight: 'inherit',
    mx: 1,
  },
  label: {
    fontWeight: 'inherit',
  },
  separator: {
    marginLeft: 1,
    display: {
      lg: 'inline-block',
      xs: 'none',
    },
  },
} as const;
