import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { businessInfoState, stepState } from '@/redux/purchasing';
import { setBusinessOrientedInfo, setStep } from '@/redux/purchasing/slice';

import type { IBusinessOrientedInfo } from '@/redux/purchasing';

const useGeneralInformationForm = () => {
  const dispatch = useDispatch();
  const businessInfo = useSelector(businessInfoState);
  const activeStep = useSelector(stepState);

  const initialValues: IBusinessOrientedInfo = useMemo(
    () =>
      businessInfo ?? {
        brandType: null,
        purchasingType: null,
        paymentCurrency: null,
        measureUnit: null,
        estimetedVolumes: '',
        listingFee: false,
        certifications: '',
        logisticRequirements: '',
        otherInformation: '',
        weight: '',
      },
    [businessInfo],
  );

  const onSubmit = useCallback(
    (value: IBusinessOrientedInfo) => {
      dispatch(setBusinessOrientedInfo(value));
      dispatch(setStep(activeStep + 1));
    },
    [dispatch, activeStep],
  );
  return { initialValues, onSubmit };
};

export default useGeneralInformationForm;
