import Box from '@mui/material/Box';
import Rating from '@mui/material/Rating';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { DetailCandidacyModalTab } from '@/components/ui/modals/DetailCandidacyModal';
import { setCandidacy } from '@/redux/awards/slice';

import type { IRatingCellProps } from './types';

const RatingCell = ({ id, value, sx }: IRatingCellProps) => {
  const dispatch = useDispatch();
  const handleSelectRow = useCallback(() => {
    dispatch(setCandidacy({ candidacyId: id, selectedTab: DetailCandidacyModalTab.Actions }));
  }, [dispatch, id]);
  return (
    <Box onClick={handleSelectRow}>
      <Rating name="rating" readOnly precision={0.5} sx={sx} value={value / 2} />
    </Box>
  );
};

export default RatingCell;
