import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/redux/store';

export const appStateSelector = ({ app }: RootState) => app;

export const isMenuOpenState = createSelector(appStateSelector, (state) => state.isMenuOpen);
export const imageLoadingState = createSelector(appStateSelector, (state) => {
  return state.imageLoading ? !!state.imageLoading.length : null;
});
