import { TextField } from '@mui/material';
import { ErrorMessage, useField } from 'formik';

import { styles } from './styles';

import type { TextFieldProps } from '@mui/material';

const TextInput = ({
  type = 'text',
  fullWidth = true,
  variant = 'outlined',
  margin = 'dense',
  size = 'small',
  helperText,
  ...props
}: TextFieldProps) => {
  const [field, meta] = useField(props.name);

  return (
    <TextField
      type={type}
      fullWidth={fullWidth}
      variant={variant}
      margin={margin}
      size={size}
      sx={styles.root}
      {...field}
      {...props}
      helperText={meta.error && meta.touched ? <ErrorMessage name={props.name} /> : helperText}
      error={meta.error && meta.touched}
    />
  );
};

export default TextInput;
