import { configureStore } from '@reduxjs/toolkit';

import { appReducer } from './app';
import { awardsReducer } from './awards';
import { editorialReducer } from './editorial';
import { goodsReducer } from './Goods';
import { purchasingReducer } from './purchasing';
import { userReducer } from './user';

import type { EnhancedStore } from '@reduxjs/toolkit';

const createStore = () =>
  configureStore({
    reducer: {
      app: appReducer,
      awards: awardsReducer,
      purchasing: purchasingReducer,
      editorial: editorialReducer,
      user: userReducer,
      goods: goodsReducer,
    },
  });

const store: EnhancedStore = createStore();

export type Store = ReturnType<typeof createStore>;
export type RootState = ReturnType<Store['getState']>;
export type AppDispatch = Store['dispatch'];

export default store;
