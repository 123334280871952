import { theme } from '@/constants/theme';

export const styles = {
  content: {
    paddingTop: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
  title: {
    marginLeft: 1,
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down('md')]: {
      maxWidth: '150px',
    },
  },
  badge: {
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.spacing(0.5),
    marginTop: 2,
  },
  list: {
    minHeight: 'calc(21.59px * 3)',
  },
  listLabel: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
} as const;
