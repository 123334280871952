import { gql } from '@apollo/client';

export const EditorialSectionFullFragment = gql`
  fragment EditorialSectionFullFragment on EditorialSection {
    id
    localId
    editorialBrand
    name
    slug
    color
    Parent {
      id
      name
      iconMedia {
        smallThumbUrl
        mediumThumbUrl
        name
      }
    }
    akas
    level
    styleClass
    iconMedia {
      smallThumbUrl
      mediumThumbUrl
      name
    }
  }
`;
