export enum QuestionSetRequest {
  Catalog = 'CATALOG',
  Price = 'PRICE',
  Resellers = 'RESELLERS',
  Contact = 'CONTACT',
}

export enum ExclusionReason {
  Price = 'PRICE',
  Packaging = 'PACKAGING',
  Logistic = 'LOGISTIC',
  Certifications = 'CERTIFICATIONS',
  ProductiveCapability = 'PRODUCTIVE_CAPABILITY',
  Other = 'OTHER',
}
