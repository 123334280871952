import { theme } from '@/constants/theme';

export const styles = {
  input: {
    display: 'flex',
    alignItem: 'center',
    columnGap: 2,
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
} as const;
