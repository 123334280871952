import { getUserData } from '@food/auth';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { CandidacyAwardFinalPosition } from '@/enums';
import { QuestionSetRequest } from '@/enums/question';
import {
  askQuestionCreate,
  createCandidacyRating,
  updateCandidacyRating,
  updateUserNote,
  userNoteCreate,
} from '@/redux/awards/thunks';

import type { INoteRequest } from '@/components/ui/forms/request/NoteForm/types';
import type { ITabProps } from '@/components/ui/modals/DetailCandidacyModal/components/types';
import type { QuestionSetCreateInput, UserNoteCreateInput } from '@/dto/requests';
import type { AppDispatch } from '@/redux/store';
import type { FormikHelpers } from 'formik';

const useMainForm = ({ candidacy }: Pick<ITabProps, 'candidacy'>) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const userData = getUserData();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const hasFinalist = useMemo(
    () =>
      candidacy?.awardResults?.some(
        ({ finalPosition, Candidacy }) =>
          Candidacy?.id === candidacy?.id && finalPosition === CandidacyAwardFinalPosition.FINALIST,
      ) ?? false,
    [candidacy?.awardResults, candidacy?.id],
  );

  const userRating = useMemo(() => {
    return candidacy?.candidacyRatings?.find((rating) => rating?.User?.id === userData?.id) ?? null;
  }, [userData.id, candidacy.candidacyRatings]);

  const handleCreateQuestion = useCallback(
    async ({ text }: INoteRequest, helpers: FormikHelpers<INoteRequest>) => {
      const goods = candidacy.goods.map((item) => ({ id: item.id }));
      const values: QuestionSetCreateInput = {
        question1: text,
        answerSets: [],
        candidacy: { id: candidacy.id },
        answerSets__inline: [{ Company: { id: candidacy.Company.id }, isSeen: false, goods }],
        requests: [QuestionSetRequest.Resellers],
        userNewsletterAccepted: false,
      };

      await dispatch(askQuestionCreate(values))
        .unwrap()
        .then(() => {
          toast.success(t('The question has been sent'));
          helpers.resetForm();
        })
        .catch(() => toast.error('Something went wrong'));
    },
    [candidacy.Company.id, candidacy.goods, candidacy.id, dispatch, t],
  );

  const userNote = useMemo(
    () =>
      candidacy.Company.userNotes
        ?.filter((item) => item.User.id == userData.id)
        ?.sort((a, b) => +new Date(a.timestamp) - +new Date(b.timestamp))?.[0] || null,
    [candidacy.Company.userNotes, userData.id],
  );

  const handleCreateNote = useCallback(
    async ({ text }: INoteRequest) => {
      if (userNote) {
        await dispatch(updateUserNote({ id: userNote.id, note: text }))
          .unwrap()
          .then(() => {
            toast.success(t('Note Was Updated'));
          })
          .catch(() => toast.error('Something went wrong'));
        return;
      }

      const values: UserNoteCreateInput = {
        Candidacy: { id: candidacy.id },
        isPrivate: true,
        note: text,
        Company: { id: candidacy.Company.id },
      };
      await dispatch(userNoteCreate(values))
        .unwrap()
        .then(() => {
          toast.success(t('Note Was Saved'));
        })
        .catch(() => toast.error('Something went wrong'));
    },
    [candidacy.Company.id, candidacy.id, dispatch, t, userNote],
  );

  const handleCreateRating = useCallback(
    (value: number) => {
      setIsSubmitting(true);
      const rating = {
        User: { id: userData.id },
        Candidacy: { id: candidacy.id },
        overall: value * 2,
        askSample: false,
      };

      if (userRating) {
        dispatch(updateCandidacyRating({ id: userRating.id, changes: rating }))
          .unwrap()
          .then(() => {
            toast.success(t('The candidacy rating was updated successfully!'));
          })
          .finally(() => setIsSubmitting(false));
      } else {
        dispatch(createCandidacyRating(rating))
          .unwrap()
          .then(() => {
            toast.success(t('The candidacy rating was updated successfully!'));
          })
          .finally(() => setIsSubmitting(false));
      }
    },
    [candidacy.id, dispatch, t, userData.id, userRating],
  );

  return {
    userNote,
    hasFinalist,
    handleCreateNote,
    handleCreateQuestion,
    isSubmitting,
    handleCreateRating,
  };
};

export default useMainForm;
