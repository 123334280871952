import { gql } from '@apollo/client';

import { MediaBaseFragment } from '@/qraphql/fragments/MediaBaseFragment';

import { EditorialSectionFullFragment } from './EditorialSectionFullFragment';

export const BrandFullFragment = gql`
  fragment BrandFullFragment on Brand {
    id
    localId
    name
    pricePositioning
    website
    logoMedia {
      ...MediaBaseFragment
    }
    description
    descriptionIta
    descriptionLong
    descriptionLongIta
    sfId
    socialInstagramPage
    socialFacebookPage
    retailChainDistribution
    gourmetShopChainDistribution
    foodServiceChainDistribution
    goodClasses {
      id
      name
      nameIta
      shortname
      shortnameIta
    }
    Company {
      id
      name
    }
    editorialSections {
      ...EditorialSectionFullFragment
    }
  }
  ${MediaBaseFragment}
  ${EditorialSectionFullFragment}
`;
