import NewIdeasImage from '@/assets/images/new-ideas.jpg';
import PrivateLabelImage from '@/assets/images/private-label.jpg';
import ProfitImage from '@/assets/images/profit.jpg';
import { CardSize } from '@/enums/cards';

import type { ISubPageCardProps } from '@/components/ui/cards/SubPageCard/types';
import type { TFunction } from 'i18next';

export const infoCards = (
  t: TFunction,
  onClick: (val: string) => () => void,
  variant = CardSize.Small,
): ISubPageCardProps[] => [
  {
    variant,
    title: t('New Ideas'),
    subTitle: t('New Ideas description'),
    onClick: onClick('/'),
    image: NewIdeasImage,
  },
  {
    variant,
    title: t('Trendings'),
    subTitle: t('Trendings description'),
    onClick: onClick('/'),
    image: ProfitImage,
  },
  {
    variant,
    title: t('Private Label'),
    subTitle: t('Private Label description'),
    onClick: onClick('/'),
    image: PrivateLabelImage,
  },
];
