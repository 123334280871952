import { createSelector } from '@reduxjs/toolkit';

import { AwardBrandType } from '@/enums';
import { stableSort } from '@/utils';

import type { Order } from '@/enums';
import type { RootState } from '@/redux/store';

export const awardsStateSelector = ({ awards }: RootState) => awards;

export const awardsState = createSelector(awardsStateSelector, (awards) => awards);

export const selectedCandidacyState = createSelector(
  awardsStateSelector,
  ({ candidacyId, selected }) => selected.candidacies.find((item) => item.id == candidacyId),
);

export const awardSelectedState = createSelector(awardsStateSelector, ({ loading, selected }) => ({
  loading,
  selected,
}));

export const candidacySelectedState = (order: Order, orderBy: string | null) =>
  createSelector(awardsStateSelector, ({ candidacyId, selected, selectedTab }) => {
    const sorted = stableSort(selected.candidacies, { order, orderBy });

    const index = sorted.findIndex(({ id }) => id === candidacyId);

    const data = {
      prev: null,
      current: null,
      next: null,
      selectedTab,
      isPrivateLabel: selected.brandType === AwardBrandType.PRIVATE_LABEL,
    };

    if (index >= 0) {
      data.prev = sorted?.[index - 1] ?? null;
      data.current = sorted[index];
      data.next = sorted?.[index + 1] ?? null;
    }

    return data;
  });
