import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { combineSx } from '@/utils';

import { styles } from './styles';
import useModalBreadcrumbs from './useModalBreadcrumbs';

import type { IModalBreadcrumbsProps } from './types';

const ModalBreadcrumbs = ({ selected, tabs, onChange }: IModalBreadcrumbsProps) => {
  const { t } = useTranslation();
  const { prevTab, nextTab } = useModalBreadcrumbs({ selected, tabs });
  return (
    <Box sx={styles.root}>
      <Button
        startIcon={<NavigateBeforeIcon fontSize="small" />}
        sx={styles.button(!prevTab)}
        onClick={onChange?.(prevTab)}
        variant="contained"
        disableElevation
      >
        <Typography component="span">{prevTab}</Typography>
      </Button>

      <List sx={styles.list}>
        {tabs.map(({ label, Icon }, index) => (
          <ListItem
            disablePadding
            key={`${label}_${index}`}
            sx={combineSx(styles.item, selected === label ? styles.itemActive : null)}
            onClick={onChange?.(label)}
          >
            <Icon sx={styles.icon} />
            <Typography sx={styles.label} component="span">
              {t(label)}
            </Typography>
            {index < tabs.length - 1 && <NavigateNextIcon sx={styles.separator} fontSize="small" />}
          </ListItem>
        ))}
      </List>
      <Button
        endIcon={<NavigateNextIcon fontSize="small" />}
        sx={styles.button(!nextTab)}
        onClick={onChange?.(nextTab)}
        variant="contained"
        disableElevation
      >
        <Typography component="span">{nextTab}</Typography>
      </Button>
    </Box>
  );
};

export default ModalBreadcrumbs;
