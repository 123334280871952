import MenuIcon from '@mui/icons-material/Menu';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AppLogo from '@/components/common/AppLogo';
import UserPopup from '@/components/layouts/MainLayout/components/UserPopup';
import { isMenuOpenState } from '@/redux/app/selectors';
import { toggleMenu } from '@/redux/app/slice';

import { styles } from './styles';

const Header = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const open = useSelector(isMenuOpenState);

  const handlerToggleMenu = useCallback(() => {
    dispatch(toggleMenu());
  }, [dispatch]);

  return (
    <AppBar color="transparent" position="fixed" sx={styles.appBar}>
      <Toolbar sx={styles.toolbar}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Tooltip title={t(open ? 'Close main menu' : 'Open main menu')}>
            <IconButton
              color="inherit"
              aria-label="toogle main menu"
              onClick={handlerToggleMenu}
              edge="start"
            >
              <MenuIcon />
            </IconButton>
          </Tooltip>
          <AppLogo sx={styles.logo} />
        </Box>

        <UserPopup />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
