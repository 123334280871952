import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { createCompanyYears, updateCompanyYears } from '@/redux/company/thunks';
import { selectedAnnualNumberState, usersRetailerState } from '@/redux/user/selectors';
import { setselectedAnnualNumber } from '@/redux/user/slice';
import { getUserAccount } from '@/redux/user/thunks';

import type { IUpdateNumberFormProps } from './types';
import type { AppDispatch } from '@/redux/store';

const useUpdateNumberForm = ({ onSubmit }: Pick<IUpdateNumberFormProps, 'onSubmit'>) => {
  const dispatch = useDispatch<AppDispatch>();
  const retailer = useSelector(usersRetailerState);
  const userYear = useSelector(selectedAnnualNumberState);

  const initialValues = useMemo(
    () =>
      userYear || {
        id: null,
        year: new Date().getFullYear(),
        revenue: '',
        numEmployees: '',
      },
    [userYear],
  );

  const updateData = useCallback(async () => {
    await dispatch(getUserAccount({}))
      .unwrap()
      .then(() => {
        dispatch(setselectedAnnualNumber(null));
        toast.success('Success');
        onSubmit();
      });
  }, [dispatch, onSubmit]);

  const onSubmitAction = useCallback(
    async ({ id, ...values }) => {
      if (id) {
        await dispatch(
          updateCompanyYears({
            id,
            changes: {
              revenue: values.revenue || null,
              numEmployees: values.numEmployees || null,
            },
          }),
        )
          .unwrap()
          .then(() => updateData())
          .catch(() => toast.error('Something went wrong'));
        return;
      }

      await dispatch(
        createCompanyYears({
          ...values,
          Retailer: { id: retailer.id },
        }),
      )
        .unwrap()
        .then(() => updateData())
        .catch(() => toast.error('Something went wrong'));
    },
    [dispatch, retailer.id, updateData],
  );

  return { initialValues, onSubmitAction };
};

export default useUpdateNumberForm;
