import { gql } from '@apollo/client';

import { BrandFullFragment } from '@/qraphql/fragments/BrandFullFragment';
import { CompanyFullFragment } from '@/qraphql/fragments/CompanyFullFragment';
import { MediaBaseFragment } from '@/qraphql/fragments/MediaBaseFragment';

import { CandidacyRefusalFullFragment } from './CandidacyRefusalFullFragment';
import { GoodCardFragment } from './GoodFullFragment';

export const CandidacyFullFragment = gql`
  fragment CandidacyFullFragment on Candidacy {
    id
    localId
    name
    exw_price
    moq
    refusal {
      ...CandidacyRefusalFullFragment
    }
    Company {
      ...CompanyFullFragment
    }
    featuredImageMedia {
      ...MediaBaseFragment
    }
    AwardCategory {
      id
      name
    }
    awardResults {
      Candidacy {
        id
      }
      finalPosition
    }
    Brand {
      ...BrandFullFragment
    }
    range
    description1
    description2
    description3
    description4
    description5
    description6
    description7
    description8
    description9
    description10
    goods {
      ...GoodCardFragment
    }
    candidacyRatings {
      id
      overall
      User {
        id
      }
    }
    launchDate
  }
  ${MediaBaseFragment}
  ${BrandFullFragment}
  ${CompanyFullFragment}
  ${GoodCardFragment}
  ${CandidacyRefusalFullFragment}
`;
