import { gql } from '@apollo/client';

export const UserNotesFullFragment = gql`
  fragment UserNotesFullFragment on UserNote {
    id
    timestamp
    note
    vote
    isPrivate
    User {
      id
      name
    }
    Candidacy {
      id
      localId
      name
    }
  }
`;
