import Box from '@mui/material/Box';
import { Fragment } from 'react';

import { CompanyPage, PropositionPage, BrandPage, RelatedProductsPage } from './components';
import useProposalPdf from './useProposalPdf';

import type { IProposalPdfProps } from './types';

const ProposalPdf = ({ candidacies, onClose }: IProposalPdfProps) => {
  const { ref, isPrivateLabel, selected } = useProposalPdf(onClose);

  return (
    <Box ref={ref} width={794}>
      {candidacies.map((candidacy, index) => (
        <Fragment key={index}>
          <CompanyPage candidacy={candidacy} />
          <PropositionPage candidacy={candidacy} award={selected} />
          {isPrivateLabel ? null : <BrandPage candidacy={candidacy} />}
          {candidacy?.goods?.length && <RelatedProductsPage candidacy={candidacy} />}
        </Fragment>
      ))}
    </Box>
  );
};

export default ProposalPdf;
