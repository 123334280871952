import useImageLoad from './useImageLoad';

import type { ImgHTMLAttributes } from 'react';

const ImageLoader = (props: ImgHTMLAttributes<HTMLImageElement>) => {
  const onLoad = useImageLoad(props.src);
  return <img {...props} onLoad={onLoad} />;
};

export default ImageLoader;
