import { formHelperTextClasses } from '@mui/material';

export const styles = {
  root: {
    [`& .${formHelperTextClasses.root}`]: {
      mx: 1,
      fontSize: '11px',
      letterSpacing: '0px',
    },
  },
  error: {
    color: 'red',
  },
} as const;
