import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import Grid from '@mui/material/Grid';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { useMemo } from 'react';

import {
  ActionsForm,
  DetailCandidacyModalTab,
} from '@/components/ui/modals/DetailCandidacyModal/types';
import { CandidacyAwardFinalPosition } from '@/enums';

import { styles } from './styles';

import type { IModalFooterProps } from './types';

const allowActionTabs = [
  DetailCandidacyModalTab.Proposition,
  DetailCandidacyModalTab.Brand,
  DetailCandidacyModalTab.RelatedProducts,
];

const ModalFooter = ({
  next,
  prev,
  candidacy,
  selectedTab,
  onChange,
  onChangeTab,
}: IModalFooterProps) => {
  const allowAction = useMemo(() => allowActionTabs.includes(selectedTab), [selectedTab]);

  const hasFinalist = useMemo(
    () =>
      candidacy?.awardResults?.some(
        ({ finalPosition, Candidacy }) =>
          Candidacy?.id === candidacy?.id && finalPosition === CandidacyAwardFinalPosition.FINALIST,
      ) ?? false,
    [candidacy?.awardResults, candidacy?.id],
  );

  return (
    <DialogActions>
      <Grid container spacing={2}>
        <Grid item lg={allowAction ? 4 : 6} xs={6}>
          <Button
            startIcon={<NavigateBeforeIcon fontSize="small" />}
            onClick={onChange(prev?.id ?? null)}
            sx={styles.button(!prev)}
            fullWidth
            disableRipple
            disableElevation
            disableFocusRipple
            disableTouchRipple
          >
            <Box component="span" sx={styles.btnTextWrapper}>
              <Typography component="span" sx={styles.btnText} textAlign="start">
                {prev?.name}
              </Typography>
            </Box>
          </Button>
        </Grid>
        <Grid
          item
          lg={4}
          justifyContent="center"
          display={{ lg: allowAction ? 'flex' : 'none', xs: 'none' }}
        >
          <Grid container columnSpacing={1}>
            <Grid item xs={4} display="flex" justifyContent="flex-start">
              <Button
                variant="outlined"
                fullWidth
                color="error"
                disabled={hasFinalist}
                onClick={onChangeTab?.(DetailCandidacyModalTab.Actions, ActionsForm.decline)}
              >
                Decline
              </Button>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="center" alignItems="center">
              <Box
                onClick={onChangeTab?.(DetailCandidacyModalTab.Actions)}
                sx={{ cursor: 'pointer' }}
              >
                <Rating readOnly precision={0.5} value={(candidacy?.rating ?? 0) / 2} />
              </Box>
            </Grid>
            <Grid item xs={4} display="flex" justifyContent="flex-end">
              <Button
                variant="contained"
                fullWidth
                color="success"
                disabled={hasFinalist}
                onClick={onChangeTab?.(DetailCandidacyModalTab.Actions, ActionsForm.talk)}
              >
                Let`s talk
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={allowAction ? 4 : 6} xs={6} display="flex" justifyContent="flex-end">
          <Button
            endIcon={<NavigateNextIcon fontSize="small" />}
            onClick={onChange(next?.id ?? null)}
            sx={styles.button(!next, true)}
            fullWidth
            disableRipple
            disableElevation
            disableFocusRipple
            disableTouchRipple
          >
            <Box component="span" sx={styles.btnTextWrapper}>
              <Typography component="span" textAlign="end" sx={styles.btnText}>
                {next?.name}
              </Typography>
            </Box>
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  );
};

export default ModalFooter;
