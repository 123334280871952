import { Box, Button, Divider, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SubPageCard } from '@/components';
import { infoCards } from '@/constants/sub-pages-card';
import { DrawerType } from '@/enums';
import { usersRetailerState } from '@/redux/user/selectors';

import BaseRightDrawer from '../BaseRightDrawer';

import { styles } from './styles';
import useInfoDrawer from './useInfoDrawer';

import type { IDrawerProps } from '../types';

const InfoDrawer = ({ open, changeType, toggleOpen }: IDrawerProps) => {
  const { onCardClickAction } = useInfoDrawer();
  const { t } = useTranslation();
  const retailer = useSelector(usersRetailerState);

  return (
    <>
      <BaseRightDrawer open={open} toggleOpen={toggleOpen} width={300}>
        {retailer && (
          <>
            <Typography variant="h5" sx={styles.title}>
              {retailer.name}
            </Typography>
            <Box component="img" src={retailer?.logoMedia?.origUrl} sx={styles.img} />
            <Button
              variant="contained"
              sx={{ mb: 3 }}
              onClick={changeType?.(DrawerType.CompanyData)}
            >
              {t('See Detailed info')}
            </Button>
          </>
        )}
        <Divider />

        <Box sx={styles.infoContainer}>
          {infoCards(t, onCardClickAction).map((item) => (
            <SubPageCard {...item} key={item.title} />
          ))}
        </Box>
      </BaseRightDrawer>
    </>
  );
};

export default InfoDrawer;
