import { theme } from '@/constants/theme';

export const styles = {
  content: {
    paddingTop: 0,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 2,
  },
  badge: {
    borderWidth: '1px',
    borderColor: theme.palette.primary.main,
    borderStyle: 'solid',
    color: theme.palette.primary.main,
    padding: theme.spacing(0.5, 1),
    height: '36px',
    borderRadius: theme.spacing(0.5),
    marginTop: 2,
  },
  listLabel: {
    marginRight: theme.spacing(2),
    fontWeight: 'bold',
  },
  edit: {
    ml: 'auto',
    mt: 2,
    color: 'white',
    fontWeight: 500,
    backgroundColor: 'rgba(0, 0, 0, 0.40)',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.50)',
    },
  },
} as const;
