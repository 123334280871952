import { theme } from '@/constants/theme';

export const styles = {
  root: {
    maxWidth: '1500px',
    m: 'auto',
    px: theme.spacing(4),
    py: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    mb: theme.spacing(4),
  },
} as const;
