import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Portal from '@mui/material/Portal';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ProposalPdf } from '@/components';

import { styles } from './styles';

import type { IPrintPdfActionProps } from './types';

const PrintPdfAction = ({ candidacies, btnText }: IPrintPdfActionProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleToggleOpen = useCallback(
    (value: boolean) => () => {
      setOpen(value);
    },
    [],
  );

  return (
    <>
      <Button
        sx={styles.button}
        variant="text"
        disableRipple
        disableElevation
        disableFocusRipple
        disableTouchRipple
        onClick={handleToggleOpen(true)}
      >
        {t(btnText)}
      </Button>
      <Backdrop sx={{ zIndex: 1205 }} open={open}>
        <CircularProgress />
      </Backdrop>
      {open && (
        <Portal>
          <Box display="none" className="pdf">
            <ProposalPdf candidacies={candidacies} onClose={handleToggleOpen} />
          </Box>
        </Portal>
      )}
    </>
  );
};

export default PrintPdfAction;
