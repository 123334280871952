import { theme } from '@/constants/theme';

export const styles = {
  title: {
    whiteSpace: 'initial',
    textAlign: 'center',
    fontWeight: 500,
  },
  img: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'contain',
    my: 2,
  },
  hq: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  annual_numbers: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    mt: 4,
  },
  button: {
    width: 'fit-content',
    m: 'auto',
    textTransform: 'capitalize',
  },
} as const;
