import Box from '@mui/material/Box';

import noImage from '@/assets/images/no-image.png';
import { combineSx } from '@/utils';

import { styles } from './styles';

import type { IBackgroundImageProps } from './types';

const BackgroundImage = ({ src, width = 90, height = 60, sx }: IBackgroundImageProps) => {
  return (
    <Box
      width={width}
      height={height}
      sx={combineSx(styles.root, sx, {
        backgroundImage: `url(${src ?? noImage})`,
      })}
    />
  );
};

export default BackgroundImage;
