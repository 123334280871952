import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import ModalBreadcrumbs from './components/ModalBreadcrumbs';
import ModalFooter from './components/ModalFooter';
import ModalHeader from './components/ModalHeader';
import { styles } from './styles';
import useDetailCandidacyModal from './useDetailCandidacyModal';

import type { IDetailCandidacyModalProps } from './types';

const DetailCandidacyModal = (props: IDetailCandidacyModalProps) => {
  const {
    prev,
    current,
    next,
    toggleCandidacyRow,
    selectedTab,
    handleChangeTab,
    actionsForm,
    handleChangeActionsForm,
    tabs,
    tab: { Tab },
  } = useDetailCandidacyModal(props);
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      sx={styles.root}
      open={Boolean(current)}
      onClose={toggleCandidacyRow(null)}
    >
      <ModalHeader candidacy={current} />
      <ModalBreadcrumbs tabs={tabs} selected={selectedTab} onChange={handleChangeTab} />
      <DialogContent>
        {current && (
          <Tab
            candidacy={current}
            actionsForm={actionsForm}
            onChangeActionsForm={handleChangeActionsForm}
          />
        )}
      </DialogContent>
      <ModalFooter
        next={next}
        selectedTab={selectedTab}
        prev={prev}
        candidacy={current}
        onChange={toggleCandidacyRow}
        onChangeTab={handleChangeTab}
      />
    </Dialog>
  );
};

export default DetailCandidacyModal;
