import { gql } from '@apollo/client';
export const CandidacyRatingFullFragment = gql`
  fragment CandidacyRatingFullFragment on CandidacyRating {
    id
    localId
    User {
      id
      name
    }
    Candidacy {
      id
      name
    }
    overall
    rating1
    rating2
    rating3
    rating4
    rating5
    comment1
    comment2
    comment3
    askSample
  }
`;
