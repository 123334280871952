import { useCallback, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';

import { addImageLoader, removeImageLoader } from '@/redux/app/slice';

const useImageLoad = (src: string) => {
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (src) {
      dispatch(addImageLoader(src));
    }
  }, [dispatch, src]);

  const onLoad = useCallback(() => {
    dispatch(removeImageLoader(src));
  }, [dispatch, src]);

  return onLoad;
};

export default useImageLoad;
