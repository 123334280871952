import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Description = ({ text }: { text: string | null }) => {
  const { t } = useTranslation();

  if (!text) {
    return null;
  }

  return (
    <>
      <Typography variant="h6" sx={{ mt: 1, fontWeight: 700 }}>
        {t('Description')}:
      </Typography>
      <Typography component="div" dangerouslySetInnerHTML={{ __html: text }} />
    </>
  );
};

export default Description;
