import { getUserData } from '@food/auth';
import { ArrowDropDown } from '@mui/icons-material';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';
import useUserPopup from './useUserPopup';
const UserPopup = () => {
  const { t } = useTranslation();
  const { anchor, settings, handleOpenMenu, handleCloseMenu } = useUserPopup();

  const userData = getUserData();

  return (
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title={t('Open user menu')}>
        <Button
          variant="outlined"
          endIcon={<ArrowDropDown />}
          onClick={handleOpenMenu}
          sx={{ textTransform: 'capitalize' }}
        >
          {userData.firstName} {userData.lastName}
        </Button>
      </Tooltip>
      <Menu
        sx={{ mt: '45px' }}
        id="menu-appbar"
        anchorEl={anchor}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchor)}
        onClose={handleCloseMenu}
      >
        {settings.map(({ Icon, ...item }) => (
          <MenuItem key={item.text} onClick={item.action} sx={styles.menuItem}>
            {!!Icon && <Icon fontSize="medium" />}
            <Typography textAlign="center">{item.text}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default UserPopup;
