import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ActionsForm } from '@/components/ui/modals/DetailCandidacyModal/types';
import { RefusalTypeFlags } from '@/enums';
import { selectedCandidacyState } from '@/redux/awards/selectors';
import { refusalAwardCreate, refusalAwardUpdate } from '@/redux/awards/thunks';

import type { IDeclinePropositionProps, IDeclinePropositionRequest } from './types';
import type { AppDispatch } from '@/redux/store';

const useDeclinePropositionForm = ({ onChangeActionsForm }: IDeclinePropositionProps) => {
  const dispatch = useDispatch<AppDispatch>();
  const candidacy = useSelector(selectedCandidacyState);

  const initialState = useMemo((): IDeclinePropositionRequest => {
    const { other, refusalTypes } = candidacy.refusal?.[0] || {};

    return {
      text: other || '',
      reasonRadios: {
        [RefusalTypeFlags.KNOWN_COMPANY]:
          refusalTypes?.includes(RefusalTypeFlags.KNOWN_COMPANY) || false,
        [RefusalTypeFlags.NON_COMPETITIVE]:
          refusalTypes?.includes(RefusalTypeFlags.NON_COMPETITIVE) || false,
        [RefusalTypeFlags.NON_DIFFERENTIATED]:
          refusalTypes?.includes(RefusalTypeFlags.NON_DIFFERENTIATED) || false,
      },
    };
  }, [candidacy.refusal]);

  const handlerSubmit = useCallback(
    async ({ text, reasonRadios }: IDeclinePropositionRequest) => {
      if (!candidacy) {
        return;
      }

      const values = {
        other: text,
        refusalTypes: [
          ...Object.entries(reasonRadios)
            .filter(([, b]) => b)
            .map((item) => item[0]),
          ...(!!text ? [RefusalTypeFlags.OTHER] : []),
        ],
        Candidacy: { id: candidacy.id },
      };

      const { id } = candidacy.refusal?.[0] || {};
      if (id) {
        await dispatch(refusalAwardUpdate({ id, values })).unwrap();
        onChangeActionsForm(ActionsForm.main)();
        toast.success('Success');
        return;
      }

      await dispatch(refusalAwardCreate(values)).unwrap();
      onChangeActionsForm(ActionsForm.main)();
      toast.success('Success');
    },
    [candidacy, dispatch, onChangeActionsForm],
  );
  return { handlerSubmit, initialState, wasRefused: !!candidacy.refusal.length };
};

export default useDeclinePropositionForm;
