import { ErrorRounded, UploadFile } from '@mui/icons-material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Box, Button, Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { UpdateNumbersModal, UploadLogoModal } from '@/components/ui';

import BaseRightDrawer from '../BaseRightDrawer';

import { styles } from './styles';
import useCompanyDataDrawer from './useCompanyDataDrawer';

import type { IDrawerProps } from '../types';
import type { FC } from 'react';

const CompanyDataDrawer: FC<IDrawerProps> = ({ open, toggleOpen }) => {
  const { t } = useTranslation();
  const {
    retailer,
    openModal,
    handleOpenModal,
    handleCloseModal,
    annualData,
    openLogoModal,
    handleOpenLogoModal,
    handleCloseLogoModal,
  } = useCompanyDataDrawer();

  return (
    <>
      <BaseRightDrawer open={open} toggleOpen={toggleOpen} width={350}>
        {retailer && (
          <>
            <Typography variant="h5" sx={styles.title}>
              {retailer.name}
            </Typography>
            <Box display="flex" flexDirection="column">
              {retailer?.logoMedia?.origUrl && (
                <Box component="img" src={retailer?.logoMedia?.origUrl} sx={styles.img} />
              )}
              <Button
                startIcon={<CloudUploadIcon />}
                sx={styles.button}
                variant="contained"
                color="success"
                onClick={handleOpenLogoModal}
              >
                {retailer?.logoMedia?.origUrl ? 'Update Logo' : 'Upload Logo'}
              </Button>
            </Box>

            <Box sx={styles.hq}>
              <Typography variant="h6" color="black">
                {t('HQ')}
              </Typography>
              <Typography sx={{ whiteSpace: 'initial' }}>
                {retailer.address1} {retailer.city} {retailer.StateProvince?.name}{' '}
                {retailer.StateProvince?.code} {retailer.Country?.name}
              </Typography>

              <Link
                href="mailto:buyer@italianfoodsourcing.com?subject=My company headquarters is wrong"
                m="auto"
              >
                <Button
                  startIcon={<ErrorRounded />}
                  sx={styles.button}
                  variant="contained"
                  color="warning"
                >
                  Wrong Address?
                </Button>
              </Link>
            </Box>

            <Box sx={styles.annual_numbers}>
              <Typography variant="h5">{t('Annual Numbers')}</Typography>

              {!annualData && <Typography variant="h6">{t('No data available')}</Typography>}
              {annualData?.map((item) => (
                <Box key={item.label}>
                  <Typography variant="h6" color="black">
                    {t(item.label)}
                  </Typography>
                  <Typography variant="body1">{item.value || 'No Data'}</Typography>
                </Box>
              ))}

              <Button
                startIcon={<UploadFile />}
                sx={styles.button}
                variant="contained"
                color="success"
                onClick={handleOpenModal}
              >
                {annualData ? 'Update numbers' : 'Create Annual Numbers'}
              </Button>
            </Box>
          </>
        )}
        <UploadLogoModal open={openLogoModal} handleClose={handleCloseLogoModal} />
        <UpdateNumbersModal open={openModal} handleClose={handleCloseModal} />
      </BaseRightDrawer>
    </>
  );
};

export default CompanyDataDrawer;
