import { theme } from '@/constants/theme';

export const styles = {
  button: {
    textTransform: 'initial',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
} as const;
