import Typography from '@mui/material/Typography';

import { SocialButtons } from '@/components';
import { CategoryElement } from '@/components/ui/lists/components';
import { editorialUtil } from '@/utils';

import type { IListType } from '@/components/ui/lists/ListProps/types';
import type { ICandidacyResponse } from '@/dto';
export const list: IListType<ICandidacyResponse>[] = [
  {
    label: 'Price Positioning',
    format: (value: ICandidacyResponse) =>
      value?.Brand?.pricePositioning ? (
        <Typography component="span" textTransform="capitalize">
          {value?.Brand?.pricePositioning?.toLowerCase()}
        </Typography>
      ) : null,
  },
  {
    label: 'Distributed in',
    format: () => null,
  },
  {
    label: 'Retail',
    rowSx: { ml: 3 },
    format: (value: ICandidacyResponse) => (value?.Brand?.retailChainDistribution ? 'Yes' : 'No'),
  },
  {
    label: 'Food Service',
    rowSx: { ml: 3 },
    format: (value: ICandidacyResponse) =>
      value?.Brand?.foodServiceChainDistribution ? 'Yes' : 'No',
  },
  {
    label: 'Gourmet Shops / Deli',
    rowSx: { ml: 3 },
    format: (value: ICandidacyResponse) =>
      value?.Brand?.gourmetShopChainDistribution ? 'Yes' : 'No',
  },
  {
    label: 'External links',
    format: (value: ICandidacyResponse) => (
      <SocialButtons
        instagramUrl={value?.Brand?.socialInstagramPage}
        facebookUrl={value?.Brand?.socialFacebookPage}
        websiteUrl={value?.Brand?.website}
      />
    ),
    rowSx: {
      display: 'flex',
      alignItems: 'center',
    },
  },
];

export const listOfRows = (shouldTrackImage = false): IListType<ICandidacyResponse>[] => [
  {
    label: 'Products Categories',
    format: (value: ICandidacyResponse) => (
      <CategoryElement
        shouldTrackImage={shouldTrackImage}
        editorial={editorialUtil.parse(value.Brand.editorialSections)}
      />
    ),
    rowSx: { display: 'flex', mb: 1 },
  },
];
