import { Suspense } from 'react';

import Loader from '../Loader/Loader';

import type { ElementType } from 'react';
function Loadable<T>(Component: ElementType) {
  return (props: T) => {
    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };
}

export default Loadable;
