import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { ErrorMessage, useField, useFormikContext } from 'formik';

import { styles } from './styles';

import type { ISelectedItem } from '@/types';
import type { AutocompleteProps, TextFieldProps } from '@mui/material';

const AutocompleteInput = <
  T extends ISelectedItem,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
>({
  autoCompleteProps,
  type = 'text',
  fullWidth = true,
  variant = 'outlined',
  margin = 'dense',
  size = 'small',
  placeholder,
  required,
  helperText,
  ...props
}: {
  autoCompleteProps?: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;
} & Omit<TextFieldProps, 'onChange'>) => {
  const [field, meta] = useField(props.name);
  const { setFieldValue } = useFormikContext();

  return (
    <Autocomplete
      onBlur={field.onBlur}
      value={field.value}
      onChange={(_, newValue) => {
        setFieldValue(props.name, newValue);
      }}
      size={size}
      renderOption={(propsRender, option) => {
        return (
          <li {...propsRender} key={option.value}>
            {option.label}
          </li>
        );
      }}
      isOptionEqualToValue={(v1, v2) => v1.value == v2.value}
      {...autoCompleteProps}
      renderInput={(params) => (
        <TextField
          type={type}
          fullWidth={fullWidth}
          variant={variant}
          margin={margin}
          size={size}
          label={props.label}
          required={required}
          sx={styles.root}
          helperText={meta.error && meta.touched ? <ErrorMessage name={props.name} /> : helperText}
          error={meta.error && meta.touched}
          placeholder={placeholder}
          {...params}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {autoCompleteProps.loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default AutocompleteInput;
