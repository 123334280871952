import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { ListProps, Description, PhotoPreview } from '@/components';

import { list, listOfRows } from './constants';

import type { ITabProps } from '../types';

const CompanyTab = ({ candidacy }: ITabProps) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} display="flex" justifyContent={{ xs: 'center', md: 'initial' }}>
            <PhotoPreview
              images={[candidacy?.Company?.logoMedia?.largeThumbUrl]}
              width={267}
              height={178}
              alt={candidacy?.Company?.name}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            {candidacy?.Company?.name && (
              <Typography variant="h4">{candidacy?.Company?.name}</Typography>
            )}
            {candidacy?.Company?.yearEstablished && (
              <Typography variant="subtitle2" color="text.secondary">
                {t('Since')} {candidacy?.Company?.yearEstablished}
              </Typography>
            )}
            {candidacy?.Company?.ownership && (
              <Typography variant="subtitle2" color="text.secondary" textTransform="capitalize">
                {candidacy?.Company?.ownership.replace('_', ' ').toLowerCase()}
              </Typography>
            )}
            <Description text={candidacy?.Company?.descriptionLong} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ListProps dataSource={candidacy} list={listOfRows()} />
        <ListProps dataSource={candidacy} list={list} twoColumn />
      </Grid>
    </Grid>
  );
};

export default CompanyTab;
