import { createSelector } from '@reduxjs/toolkit';

import type IPurchasingState from './types';
import type { RootState } from '@/redux/store';

export const purchasingStateSelector = ({ purchasing }: RootState): IPurchasingState => purchasing;

export const generalInfoState = createSelector(
  purchasingStateSelector,
  (state) => state.generalInfo,
);

export const businessInfoState = createSelector(
  purchasingStateSelector,
  (state) => state.businessOrientedInfo,
);

export const descriptionsState = createSelector(
  purchasingStateSelector,
  (state) => state.priceExpectationsAndInvestments,
);

export const stepState = createSelector(purchasingStateSelector, (state) => state.step);
export const idState = createSelector(purchasingStateSelector, (state) => state.id);

export const requestState = createSelector(purchasingStateSelector, (state) => state);
