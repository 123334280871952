import { gql } from '@apollo/client';

import { MediaBaseFragment } from '@/qraphql/fragments/MediaBaseFragment';

import { EditorialSectionFullFragment } from './EditorialSectionFullFragment';
import { UserNotesFullFragment } from './UserNoteFullFragment';

export const CompanyFullFragment = gql`
  fragment CompanyFullFragment on Company {
    id
    sfId
    localId
    name
    billingName
    website
    companyEmail
    description
    descriptionIta
    descriptionLong
    descriptionLongIta
    yearEstablished
    industry
    ownership
    pecEmail
    plProducer
    vatNumber
    vatOpeningDate
    companyCertifications {
      id
      name
      akas
    }
    logoMedia {
      ...MediaBaseFragment
    }
    billingBranch {
      id
      name
      address1
      StateProvince {
        id
        name
      }
    }
    userNotes {
      ...UserNotesFullFragment
    }
    editorialSections {
      ...EditorialSectionFullFragment
    }
  }
  ${UserNotesFullFragment}
  ${MediaBaseFragment}
  ${EditorialSectionFullFragment}
`;
