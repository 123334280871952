import { gql } from '@apollo/client';

import { UserNotesFullFragment } from '@/qraphql';
import { client } from '@/utils';

import type { IEntityResponse, UserNote, UserNoteCreateInput } from '@/dto';

const createUserNote = async (values: UserNoteCreateInput) => {
  return client.mutate<IEntityResponse<UserNote>>({
    mutation: gql`
      mutation AddNoteMutation($values: UserNoteCreateInput!) {
        entity: userNoteCreate(values: $values) {
          ...UserNotesFullFragment
        }
      }
      ${UserNotesFullFragment}
    `,
    variables: { values },
  });
};

const patchUserNote = async ({ id, note }: { id: string; note: string }) => {
  return client.mutate<IEntityResponse<UserNote>>({
    mutation: gql`
      mutation PatchNoteMutation($id: ID!, $changes: UserNotePatchInput!) {
        entity: userNotePatch(id: $id, changes: $changes) {
          ...UserNotesFullFragment
        }
      }
      ${UserNotesFullFragment}
    `,
    variables: { id, changes: { note } },
  });
};

const userNoteService = {
  patchUserNote,
  createUserNote,
};

export default userNoteService;
