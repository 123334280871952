import Grid from '@mui/material/Grid';

import { TalkPropositionForm } from '@/components/ui/forms';

import type { ITabProps } from '@/components/ui/modals/DetailCandidacyModal/components/types';

const TalkForm = ({ onChangeActionsForm, candidacy }: ITabProps) => {
  return (
    <Grid item sm={12}>
      <TalkPropositionForm
        propositionId={candidacy.id}
        awardCategoryId={candidacy?.AwardCategory?.id}
        onChangeActionsForm={onChangeActionsForm}
      />
    </Grid>
  );
};

export default TalkForm;
