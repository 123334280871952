import { auth } from '@food/auth';
import { Channel } from '@food/sync';

import { UserRole } from '@/enums';
import { config } from '@/utils';

import type { RedirectHandler } from '@food/auth';

export const authConfig = {
  AUTH_ROLES: [UserRole.Buyer],
  TARGET_SITE: config.foodApi.targetSite,
  AUTH_ENDPOINT: config.foodApi.endpoint.auth,
  // LOGO: logo,
};
const onRedirect: RedirectHandler = (user, redirectURL = '/') => {
  if (user && window.location.pathname === '/login') {
    window.location.href = redirectURL;
    return;
  }
};
const authManager = auth(authConfig);
const authChannel = new Channel('@food/auth');

authChannel.onmessage = (events) => {
  onRedirect(events.data?.args?.newUser, '/');
};

const authUtil = { authManager, authConfig, onRedirect };

export default authUtil;
