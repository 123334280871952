import { format } from 'date-fns';

import type { ICandidacyResponse } from '@/dto';

export const list = [
  {
    label: 'Close Date',
    key: 'candidaciesCloseAt',
    format: (value: string) => format(new Date(value), 'MM/dd/yyyy'),
  },
  {
    label: 'N° Propositions',
    key: 'candidacies',
    format: (value: ICandidacyResponse[]) => value.length,
  },
];
