import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StarIcon from '@mui/icons-material/Star';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { LinkButton } from '@/components';

import AwardWinnerCard from '../AwardWinnerCard';

import { list } from './constants';
import { styles } from './styles';
import useAwardCard from './useAwardCard';

import type { IAwardResponse } from '@/dto';

const AwardCard = ({ award }: { award: IAwardResponse }) => {
  const { t } = useTranslation();
  const { isConcluded, winner } = useAwardCard(award);

  return (
    <Card>
      <CardContent sx={styles.content}>
        <Box sx={styles.header}>
          <Box sx={styles.titleBox}>
            <Avatar>
              <StarIcon />
            </Avatar>
            <Typography sx={styles.title}>{award.name}</Typography>
          </Box>
          <Box sx={styles.badge}>{award.status.replace('_', ' ')}</Box>
        </Box>
        <Box display="flex" flexWrap="wrap">
          <Box flexGrow={1} mt={2}>
            <List disablePadding sx={styles.list}>
              {list.map(({ label, key, format }) =>
                award[key] ? (
                  <ListItem key={`${label}_${key}`} disablePadding>
                    <Typography component="span" sx={styles.listLabel}>
                      {t(label)}
                    </Typography>
                    <Typography fontSize="0.9rem" component="span">
                      {format?.(award[key]) ?? award[key]}
                    </Typography>
                  </ListItem>
                ) : null,
              )}
            </List>
            <LinkButton
              variant={isConcluded ? 'outlined' : 'contained'}
              color="success"
              href={`/purchasing-request/${award.id}`}
              sx={{ marginTop: 3 }}
              endIcon={<NavigateNextIcon />}
            >
              View Shortlist
            </LinkButton>
          </Box>
          {isConcluded && <AwardWinnerCard mt={2} candidacy={winner} />}
        </Box>
      </CardContent>
    </Card>
  );
};

export default AwardCard;
