import { createSlice, isFulfilled } from '@reduxjs/toolkit';

import { DetailCandidacyModalTab } from '@/components/ui/modals/DetailCandidacyModal';
import { PAGE_LIMIT } from '@/constants';

import {
  getAwards,
  getAward,
  createAwardCategory,
  createResultAward,
  createCandidacyRating,
  updateCandidacyRating,
  getListOfRelatedProducts,
  updateUserNote,
  userNoteCreate,
  refusalAwardCreate,
  refusalAwardUpdate,
} from './thunks';

import type IAwardsState from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IAwardsState = {
  awards: [],
  loading: true,
  relatedLoading: true,
  hasNextPage: false,
  total: 0,
  offset: -1,
  selected: null,
  candidacyId: null,
  selectedTab: DetailCandidacyModalTab.Company,
};

export const awardsSlice = createSlice({
  name: 'awards',
  initialState,
  reducers: {
    resetAwardsState(state) {
      state.awards = [];
      state.loading = true;
      state.selected = null;
    },
    changeSelectedTab(state, { payload }: PayloadAction<DetailCandidacyModalTab>) {
      state.selectedTab = payload;
    },
    setCandidacy(
      state,
      {
        payload,
      }: PayloadAction<{ candidacyId: string | null; selectedTab?: DetailCandidacyModalTab }>,
    ) {
      state.candidacyId = payload.candidacyId;
      if (payload?.selectedTab !== undefined) {
        state.selectedTab = payload.selectedTab;
      } else {
        state.selectedTab = DetailCandidacyModalTab.Company;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAwards.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAwards.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAwards.fulfilled, (state, { payload }) => {
      state.awards = [...state.awards, ...payload.data.entities.edges.map((value) => value.node)];
      state.total = payload.data.entities.total;
      state.hasNextPage = payload.data.entities.pageInfo.hasNextPage;
      state.offset += PAGE_LIMIT;
      state.loading = false;
    });

    builder.addCase(getListOfRelatedProducts.pending, (state) => {
      state.relatedLoading = true;
    });
    builder.addCase(getListOfRelatedProducts.rejected, (state) => {
      state.relatedLoading = false;
    });
    builder.addCase(getListOfRelatedProducts.fulfilled, (state, { payload }) => {
      const related = payload.data.goodsDir.edges.map(({ node }) => node);

      state.selected.candidacies = state.selected.candidacies.map((item) => ({
        ...item,
        related: related.filter((rel) => item.Company.id == rel.Brand.Company.id),
      }));
    });

    builder.addCase(getAward.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAward.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getAward.fulfilled, (state, { payload }) => {
      state.selected = {
        ...payload.data.entity,
        candidacies: payload.data.entity.candidacies.map((candidacy) => ({
          ...candidacy,
          Company: {
            ...candidacy.Company,
            userNotes: candidacy.Company?.userNotes?.filter(
              (item) => item.Candidacy?.id == candidacy?.id,
            ),
          },
          related: [],
          rating: candidacy.candidacyRatings?.length
            ? Math.floor(
                candidacy.candidacyRatings.reduce((acc, rating) => acc + rating?.overall ?? 0, 0) /
                  candidacy.candidacyRatings.length,
              )
            : 0,
        })),
      };
      state.loading = false;
    });

    builder.addCase(createAwardCategory.fulfilled, (state, { payload }) => {
      if (state.selected) {
        state.selected.categories = [...state.selected.categories, payload.data.entity];
      }
    });

    builder.addCase(createResultAward.fulfilled, (state, { payload }) => {
      if (state.selected) {
        state.selected.candidacies = [
          ...state.selected.candidacies.map((candidacy) => {
            if (candidacy?.id === payload.data.entity?.Candidacy?.id) {
              candidacy.awardResults.push(payload.data.entity);
            }
            return candidacy;
          }),
        ];
      }
    });

    builder.addCase(createCandidacyRating.fulfilled, (state, { payload }) => {
      if (state.selected) {
        state.selected = {
          ...state.selected,
          candidacies: [
            ...(state.selected?.candidacies?.map((candidacy) => {
              if (candidacy.id === payload.data.entity?.Candidacy?.id) {
                candidacy.candidacyRatings.push(payload.data.entity);
                candidacy.rating = candidacy.candidacyRatings?.length
                  ? Math.floor(
                      candidacy.candidacyRatings.reduce(
                        (acc, rating) => acc + rating?.overall ?? 0,
                        0,
                      ) / candidacy.candidacyRatings.length,
                    )
                  : 0;
              }
              return candidacy;
            }) ?? []),
          ],
        };
      }
    });

    builder.addCase(updateCandidacyRating.fulfilled, (state, { payload }) => {
      if (state.selected) {
        state.selected = {
          ...state.selected,
          candidacies: [
            ...(state.selected?.candidacies?.map((candidacy) => {
              if (candidacy.id === payload.data.entity?.Candidacy?.id) {
                candidacy.candidacyRatings = candidacy.candidacyRatings.map((rating) => {
                  if (rating?.id === payload.data.entity.id) {
                    return payload.data.entity;
                  }
                  return rating;
                });
                candidacy.rating = candidacy.candidacyRatings?.length
                  ? Math.floor(
                      candidacy.candidacyRatings.reduce(
                        (acc, rating) => acc + rating?.overall ?? 0,
                        0,
                      ) / candidacy.candidacyRatings.length,
                    )
                  : 0;
              }
              return candidacy;
            }) ?? []),
          ],
        };
      }
    });

    builder.addMatcher(isFulfilled(userNoteCreate, updateUserNote), (state, { payload }) => {
      if (!state.candidacyId) {
        return;
      }
      state.selected.candidacies = state.selected.candidacies.map((item) =>
        item.id != state.candidacyId
          ? item
          : { ...item, Company: { ...item.Company, userNotes: [payload.data.entity] } },
      );
    });

    builder.addMatcher(
      isFulfilled(refusalAwardUpdate, refusalAwardCreate),
      (state, { payload }) => {
        if (!state.candidacyId) {
          return;
        }
        state.selected.candidacies = state.selected.candidacies.map((item) =>
          item.id != state.candidacyId ? item : { ...item, refusal: [payload.data.entity] },
        );
      },
    );
  },
});

export const { resetAwardsState, setCandidacy, changeSelectedTab } = awardsSlice.actions;

export default awardsSlice.reducer;
