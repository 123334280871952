import { createSlice } from '@reduxjs/toolkit';

import { getEditorialSections } from './thunks';

import type IEditorialState from './types';

const initialState: IEditorialState = {
  editorialSections: [],
  loading: false,
};

export const editorialSlice = createSlice({
  name: 'editorial',
  initialState,
  reducers: {
    resetEditorialState(state) {
      state.editorialSections = [];
      state.loading = false;
    },
    setLoading(state, { payload }) {
      state.loading = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEditorialSections.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getEditorialSections.fulfilled, (state, { payload }) => {
      state.editorialSections = payload.data.entities.edges?.map((value) => value.node);
      state.loading = false;
    });
    builder.addCase(getEditorialSections.rejected, (state) => {
      state.loading = false;
      state.editorialSections = [];
    });
  },
});

export const { resetEditorialState, setLoading } = editorialSlice.actions;

export default editorialSlice.reducer;
