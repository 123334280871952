import { env } from './env-runtime';
import { EnvMode } from './env.type';

import type { IEnv } from './env.type';

const config: IEnv = {
  app: {
    name: env.string('REACT_APP_NAME', ''),
    backendUrl: env.string('REACT_APP_BACKEND_URL') || '',
    isDev: env.string<EnvMode>('NODE_ENV', EnvMode.DEV_ENV) === EnvMode.DEV_ENV,
    isProd: env.string<EnvMode>('NODE_ENV', EnvMode.DEV_ENV) === EnvMode.PROD_ENV,
  },
  foodApi: {
    targetSite: env.string('REACT_APP_FOOD_TARGET_SITE', 'SOURCINGBUYER'),
    baseUrl: env.string('REACT_APP_FOOD_API_URL', ''),
    endpoint: {
      auth: env.string('REACT_APP_FOOD_API_URL', '') + '/api/authenticate',
      tracking: env.string('REACT_APP_FOOD_API_URL', '') + '/api/bucket/tracking',
    },
  },
};

export default config;
