import { Add, Close } from '@mui/icons-material';
import { Box, Button, Divider, IconButton, Tooltip, Typography } from '@mui/material';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { SelectInput, TextInput } from '@/components/ui/inputs';

import { RequestActionButtons } from '../components';

import { boolSelectOptions, boolSelectOptionsDefault, sendUntilOptions } from './constants';
import { schema } from './schema';
import { styles } from './styles';
import useDescriptionForm from './useDescriptionForm';

import type { IRequestFormProps } from '../types';

const DescriptionForm = ({ max = 5, ...props }: IRequestFormProps & { max?: number }) => {
  const { onSubmit, initialValues, removeItem, addNewItem } = useDescriptionForm();
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ values, setFieldValue }) => (
        <Form>
          <Field as={TextInput} label={t('Target Price')} name="targetPrice" />
          <Field
            as={SelectInput}
            label={t('Expected Marketing Investment Input')}
            name="marketingIvestmentExpected"
            options={boolSelectOptionsDefault}
            default={false}
          />

          <Field
            as={SelectInput}
            label={t('Select your preferred application deadline*')}
            options={sendUntilOptions}
            name="candidaciesCloseAt"
            helperText="We need a minimum of 30 days to collect proposition and validate them"
            required
          />

          <Field
            as={TextInput}
            label={t('Product Description input')}
            name="benchmarkGood"
            multiline
            maxRows={10}
            minRows={3}
            helperText="Describe what you’re looking for: a range or a specific product?, Packaging type? weight? It will substitute an actual product? Do you have benchmarks to emulate"
          />

          {values.descriptions.map((_, ind) => (
            <Box key={ind}>
              <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                <Typography variant="h6" mb={1}>
                  {t('Question ') + (ind + 1)}
                </Typography>
                <Tooltip title={t('Remove question')}>
                  <IconButton
                    onClick={() => removeItem(setFieldValue, values.descriptions, ind)}
                    edge="end"
                  >
                    <Close />
                  </IconButton>
                </Tooltip>
              </Box>

              <Field
                as={TextInput}
                label={t('Ask about Product/Supplier/Certifications')}
                name={`descriptions[${ind}].title`}
                multiline
                maxRows={5}
                minRows={2}
                required
              />
              <Field
                as={TextInput}
                label={`${t('Helper text for this question')}`}
                name={`descriptions[${ind}].helperText`}
                multiline
                maxRows={4}
              />
              <Box sx={styles.input}>
                {/* <Field
                  fullWidth={false}
                  as={TextInput}
                  label={`${t('Max length of the answer')}`}
                  name={`descriptions[${ind}].maxLength`}
                  type="number"
                  required
                  sx={{ flexGrow: 1 }}
                /> */}
                <Field
                  fullWidth={false}
                  as={SelectInput}
                  label={t('Answer')}
                  name={`descriptions[${ind}].isRequired`}
                  options={boolSelectOptions}
                  default={false}
                  sx={{ flexGrow: 1 }}
                />
              </Box>

              <Divider sx={{ my: 2 }} />
            </Box>
          ))}

          {values.descriptions.length < max && (
            <Box display="flex" justifyContent="center">
              <Button
                endIcon={<Add />}
                variant="outlined"
                onClick={() => addNewItem(setFieldValue, values.descriptions)}
              >
                {t('Add One')}
              </Button>
            </Box>
          )}
          <RequestActionButtons {...props} />
        </Form>
      )}
    </Formik>
  );
};

export default DescriptionForm;
