import { ApolloClient, InMemoryCache, ApolloLink } from '@apollo/client';
import { RenewLink, targetSiteLink } from '@food/auth';
import { createUploadLink } from 'apollo-upload-client';

import { config, authUtil } from '@/utils';

const link = ApolloLink.from([
  new (targetSiteLink({ TARGET_SITE: config.foodApi.targetSite }))(),
  // eslint-disable-next-line
  new (RenewLink(authUtil.authManager))() as any,
  // new HttpLink({ uri: config.foodApi.baseUrl + '/graphql' }),
  createUploadLink({
    uri: config.foodApi.baseUrl + '/graphql',
  }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

export default client;
