import { gql } from '@apollo/client';

import { BrandFullFragment } from '@/qraphql/fragments/BrandFullFragment';
import { CandidacyFullFragment } from '@/qraphql/fragments/CandidacyFullFragment';
import { EditorialSectionFullFragment } from '@/qraphql/fragments/EditorialSectionFullFragment';
import { MediaBaseFragment } from '@/qraphql/fragments/MediaBaseFragment';

export const AwardFullFragment = gql`
  fragment AwardFullFragment on Award {
    id
    localId
    name
    edition
    year
    visibility
    publicDescription
    eventDescription
    targetSites
    langCode
    logoMedia {
      ...MediaBaseFragment
    }
    status
    descriptionForProducer
    descriptionForVoter
    descriptionProducerHighlights
    maxPerCompanyCandidacies
    maxGoodsPerCandidacy
    candidaciesCloseAt
    votesCloseAt
    autovoterRoles
    ratingOverallLabel
    rating1Label
    rating2Label
    rating3Label
    rating4Label
    rating5Label
    comment1Label
    comment1Max
    comment2Label
    comment2Max
    comment3Label
    comment3Max
    website
    description1Label
    description1Max
    description2Label
    description2Max
    description3Label
    description3Max
    description4Label
    description4Max
    description5Label
    description5Max
    description6Label
    description6Max
    description7Label
    description7Max
    description8Label
    description8Max
    description9Label
    description9Max
    description10Label
    description10Max
    visibility
    options
    extraMediaType
    extraMediaLabel
    categories {
      id
      name
      special
    }
    candidacies {
      ...CandidacyFullFragment
    }
    invitedUsers {
      id
      roles
      User {
        id
        name
      }
    }
    vademecumCompany
    vademecumGoods
    vademecumCandidacies
    vademecumInvited
    supportEmail
    regulationsMedia {
      ...MediaBaseFragment
    }
    contractMedia {
      ...MediaBaseFragment
    }

    type
    pricePosition
    brandType
    productType
    purchasingType
    paymentCurrency
    estimatedVolumes
    measureUnit
    benchmarkGood
    listingFee
    marketingInvestmentExpected
    EditorialSection {
      ...EditorialSectionFullFragment
    }
  }
  ${MediaBaseFragment}
  ${EditorialSectionFullFragment}
  ${BrandFullFragment}
  ${CandidacyFullFragment}
`;
