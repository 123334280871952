import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

import type { IListProps } from './types';

const ListProps = <T extends object>({ list, dataSource, twoColumn }: IListProps<T>) => {
  const { t } = useTranslation();
  return (
    <List disablePadding sx={twoColumn ? styles.listTwoColumn : undefined}>
      {list.map(({ label, format, rowSx }, index) => (
        <ListItem key={`${label}_${index}`} disablePadding>
          <Box sx={rowSx}>
            <Typography whiteSpace="nowrap" fontWeight="bold" sx={{ mr: 1 }} component="span">
              {t(label)}:
            </Typography>
            <Typography component="span" color="text.secondary">
              {format(dataSource)}
            </Typography>
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default ListProps;
