import { lazy } from 'react';

import { Loadable } from '@/components/common/loaders';

const HomeView = Loadable(lazy(() => import('@/components/views/HomeView')));
const FaqView = Loadable(lazy(() => import('@/components/views/FaqView')));
const ParchasingRequestView = Loadable(
  lazy(() => import('@/components/views/ParchasingRequestView')),
);
const ParchasingRequestDetailView = Loadable(
  lazy(() => import('@/components/views/ParchasingRequestDetailView')),
);
const CreateRequestView = Loadable(lazy(() => import('@/components/views/CreateRequestView')));
const EditRequestView = Loadable(lazy(() => import('@/components/views/EditRequestView')));
const NotFoundView = Loadable(lazy(() => import('@/components/views/NotFoundView')));

export const routes = [
  {
    path: '/',
    exact: true,
    component: HomeView,
  },
  {
    path: '/faq',
    exact: true,
    component: FaqView,
  },
  {
    path: '/purchasing-request',
    exact: true,
    component: ParchasingRequestView,
  },
  {
    path: '/purchasing-request/create',
    exact: true,
    component: CreateRequestView,
  },
  {
    path: '/purchasing-request/:id/edit',
    exact: true,
    component: EditRequestView,
  },
  {
    path: '/purchasing-request/:id',
    exact: true,
    component: ParchasingRequestDetailView,
  },
  {
    path: '/*',
    component: NotFoundView,
  },
];
