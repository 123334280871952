import { useCallback, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { ActionsForm } from '@/components/ui/modals/DetailCandidacyModal';
import { CandidacyAwardFinalPosition } from '@/enums';
import { awardSelectedState } from '@/redux/awards/selectors';
import { createAwardCategory, createResultAward } from '@/redux/awards/thunks';

import type { ITalkPropositionProps } from './types';
import type { AppDispatch } from '@/redux/store';

const useTalkPropositionForm = ({
  propositionId,
  awardCategoryId,
  onChangeActionsForm,
}: ITalkPropositionProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const { selected } = useSelector(awardSelectedState);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const categoryId = useMemo(() => {
    return awardCategoryId ?? selected.categories?.[0]?.id;
  }, [awardCategoryId, selected.categories]);

  const handelSubmit = useCallback(async () => {
    setIsSubmitting(true);
    let cId = categoryId;

    if (!cId) {
      const category = await dispatch(
        createAwardCategory({
          description: selected.productType,
          Award: { id: selected.id },
          name: selected.productType,
          special: false,
        }),
      ).unwrap();
      cId = category.data.entity.id;
    }

    dispatch(
      createResultAward({
        AwardCategory: { id: cId },
        Candidacy: { id: propositionId },
        finalPosition: CandidacyAwardFinalPosition.FINALIST,
      }),
    )
      .unwrap()
      .then(() => {
        onChangeActionsForm(ActionsForm.main)();
        toast.success(t('The candidacy was approved'));
      })
      .catch((e) => toast.error(e?.message ?? 'Something went wrong!'))
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [
    categoryId,
    dispatch,
    onChangeActionsForm,
    propositionId,
    selected.id,
    selected.productType,
    t,
  ]);
  return { handelSubmit, isSubmitting };
};

export default useTalkPropositionForm;
