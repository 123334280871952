import { gql } from '@apollo/client';

import { client } from '@/utils';

import type { IEditorialSectionResponse, IPaginationResponse } from '@/dto';

const getEditorialSections = async ({
  first = 50,
  orderBy = undefined,
  orderDesc = undefined,
  filter = {},
}: {
  first?: number;
  cursor?: string;
  orderBy?: string;
  orderDesc?: string;
  filter?: {};
}) => {
  return client.query<IPaginationResponse<IEditorialSectionResponse>>({
    query: gql`
      query EditorialSectionsListQuery(
        $first: Int
        $cursor: String
        $orderBy: EditorialSectionOrderBy
        $orderDesc: Boolean
        $filter: EditorialSectionFilterInput
      ) {
        entities: editorialSections(
          first: $first
          after: $cursor
          orderBy: $orderBy
          orderDesc: $orderDesc
          filter: $filter
        ) {
          edges {
            node {
              id
              localId
              editorialBrand
              name
            }
          }
          total
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
    `,
    variables: { first, orderBy, orderDesc, filter },
  });
};

const editorialSectionsService = {
  getEditorialSections,
};
export default editorialSectionsService;
