export const styles = {
  logo: {
    display: {
      sm: 'flex',
      xs: 'none',
    },
  },
  titleWrapper: {
    position: 'relative',
    flexGrow: 1,
    minHeight: '32.02px',
  },
  title: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  moq: {
    '&:before': {
      content: '"|"',
      display: 'inline-block',
      mx: 1,
    },
  },
} as const;
