import { ImageCell } from '../AwardPropositionTable/components';

import type { IGoodResponse } from '@/dto';
import type { TableCellProps } from '@mui/material';

interface ICell<T> {
  key: string;
  label: string;
  th?: TableCellProps;
  td?: TableCellProps;
  sort?: boolean;
  align?: TableCellProps['align'];
  format?: (value: T) => JSX.Element | string;
}

export const cells: ICell<IGoodResponse>[] = [
  {
    label: 'Image',
    key: 'featuredImageMedia.origUrl',
    align: 'center',
    format: (value) => <ImageCell src={value?.featuredImageMedia?.mediumThumbUrl} />,
  },
  {
    label: 'Product Name',
    key: 'name',
    align: 'center',
    sort: true,
  },
  {
    label: 'Range',
    key: 'range',
    sort: true,
    align: 'center',
  },
  {
    label: '№ of SKUS',
    key: 'skus.length',
    sort: true,
    align: 'center',
  },
  {
    label: 'Shelf Life',
    key: 'shelfLifeLength',
    sort: true,
    align: 'center',
  },
];
