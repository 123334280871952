import { useMemo } from 'react';

import type { IModalBreadcrumbsProps } from './types';

const useModalBreadcrumbs = ({ selected, tabs }: Omit<IModalBreadcrumbsProps, 'onChange'>) => {
  const nextTab = useMemo(() => {
    const index = tabs.findIndex(({ label }) => label === selected);

    if (index == -1) {
      return null;
    }
    return tabs?.[index + 1]?.label ?? null;
  }, [selected, tabs]);

  const prevTab = useMemo(() => {
    const index = tabs.findIndex(({ label }) => label === selected);

    if (index == -1) {
      return null;
    }
    return tabs?.[index - 1]?.label ?? null;
  }, [selected, tabs]);

  return { nextTab, prevTab };
};

export default useModalBreadcrumbs;
