import { add } from 'date-fns';

import { AwardAccessRole, AwardType, AwardVisibility } from '@/enums';

import { SendUntilSelect } from '../BusinessInfoForm';

import type { IDescriptionInfo } from '@/redux/purchasing';

export const defaultValues = {
  type: AwardType.PURCHASING_REQUEST,
  year: new Date().getFullYear(),
  langCode: 'en_US',
  visibility: AwardVisibility.SOURCING,
  maxPerCompanyCandidacies: 1,
  maxGoodsPerCandidacy: 20,
  votesCloseAt: add(new Date(), { days: 30 }).toISOString(),
  ratingOverallLabel: 'Rating',
  autovoterRoles: [AwardAccessRole.BUYER],
  supportEmail: 'supplier@italianfood.sourcing.com',
  description1Max: 999,
  description2Max: 999,
  description3Max: 999,
  description4Max: 999,
  description5Max: 999,
  description6Max: 999,
  description7Max: 999,
  description8Max: 999,
  description9Max: 999,
  description10Max: 999,
  description1Label: null,
  description2Label: null,
  description3Label: null,
  description4Label: null,
  description5Label: null,
  description6Label: null,
  description7Label: null,
  description8Label: null,
  description9Label: null,
  description10Label: null,
};

export const boolSelectOptions: { value: boolean | null; label: string }[] = [
  { value: false, label: 'Answer is Optional' },
  { value: true, label: 'Answer is Required' },
];

export const boolSelectOptionsDefault: { value: boolean | null; label: string }[] = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' },
];

export const sendUntilOptions: { value: SendUntilSelect; label: string }[] = [
  // { value: SendUntilSelect.Low, label: 'Low Priority (60 Days)' },
  { value: SendUntilSelect.High, label: '30 Days' },
  { value: SendUntilSelect.Medium, label: '40 Days' },
];

export const emptyItem: IDescriptionInfo = {
  title: '',
  helperText: '',
  isRequired: false,
  // maxLength: null,
};
