import { useMediaQuery } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { theme } from '@/constants/theme';
import { getUserAccount } from '@/redux/user/thunks';

import { drawersMap } from './constants';

import type { IDrawerControllerProps } from './types';
import type { DrawerType } from '@/enums';

const DrawerController = ({ type }: IDrawerControllerProps) => {
  const [drawerType, setDrawerType] = useState(type);
  const DrawerElement = drawersMap[drawerType];
  const dispatch = useDispatch();

  const changeType = useCallback((val: DrawerType) => () => setDrawerType(val), []);
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(
    () =>
      setOpen((prev) => {
        if (prev) {
          setDrawerType(type);
        }
        return !prev;
      }),
    [type],
  );

  useEffect(() => {
    if (open) {
      dispatch(getUserAccount({}));
    }
  }, [dispatch, open]);

  useEffect(() => {
    setOpen(isDesktop);
  }, [isDesktop]);

  return <DrawerElement changeType={changeType} open={open} toggleOpen={toggleOpen} />;
};

export default DrawerController;
