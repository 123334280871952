import { gql } from '@apollo/client';

import { CandidacyRefusalFullFragment } from '@/qraphql';
import { AwardFullFragment } from '@/qraphql/fragments/AwardFullFragment';
import { AwardResultFullFragment } from '@/qraphql/fragments/AwardResultFullFragment';
import { MediaBaseFragment } from '@/qraphql/fragments/MediaBaseFragment';
import { client } from '@/utils';

import type {
  ICandidacyAwardResultResponse,
  IAwardResponse,
  IAwardResultCreateRequest,
  ICreateAwardRequest,
  IEntityResponse,
  IPaginationResponse,
  IUpdateAwardRequest,
  IRefusalReasonRequest,
  IRefusalReasonResponse,
} from '@/dto';

const getAward = async ({ id }: { id: string }) => {
  return client.query<IEntityResponse<IAwardResponse>>({
    fetchPolicy: 'no-cache',
    query: gql`
      query AwardDetailQuery($id: ID!) {
        entity: node(id: $id) {
          ...AwardFullFragment
        }
      }
      ${AwardFullFragment}
    `,
    variables: { id },
  });
};
const getAwards = async ({
  first = 50,
  cursor = undefined,
  orderBy = undefined,
  orderDesc = undefined,
  filter = {},
}: {
  first?: number;
  cursor?: string;
  orderBy?: string;
  orderDesc?: string;
  filter?: {};
}) => {
  return client.query<IPaginationResponse<IAwardResponse>>({
    fetchPolicy: 'no-cache',
    query: gql`
      query AwardListQuery(
        $first: Int
        $cursor: String
        $orderBy: AwardOrderBy
        $orderDesc: Boolean
        $filter: AwardFilterInput
      ) {
        entities: awards(
          first: $first
          after: $cursor
          orderBy: $orderBy
          orderDesc: $orderDesc
          filter: $filter
        ) {
          edges {
            node {
              id
              localId
              name
              edition
              year
              status
              visibility
              votesCloseAt
              candidaciesCloseAt
              candidacies {
                id
                awardResults {
                  finalPosition
                }
                Company {
                  name
                  logoMedia {
                    ...MediaBaseFragment
                  }
                }
              }
            }
          }
          total
          pageInfo {
            hasNextPage
            hasPreviousPage
          }
        }
      }
      ${MediaBaseFragment}
    `,
    variables: { first, cursor, orderBy, orderDesc, filter },
  });
};

const createAward = async ({ values }: ICreateAwardRequest) => {
  return client.mutate<IEntityResponse<{ id: number }>>({
    mutation: gql`
      mutation AwardCreateMutation($values: AwardCreateInput!) {
        entity: awardCreate(values: $values) {
          id
        }
      }
    `,
    variables: { values },
  });
};

const updateAward = async ({ id, values }: IUpdateAwardRequest) => {
  return client.mutate<IEntityResponse<{ id: number }>>({
    mutation: gql`
      mutation awardPatchMutation($id: ID!, $changes: AwardPatchInput!) {
        entity: awardPatch(id: $id, changes: $changes) {
          id
        }
      }
    `,
    variables: { id, changes: values },
  });
};

const resultCreateAward = async (values: IAwardResultCreateRequest) => {
  return client.mutate<IEntityResponse<ICandidacyAwardResultResponse>>({
    mutation: gql`
      mutation AwardResultCreateMutation($values: AwardResultCreateInput!) {
        entity: awardResultCreate(values: $values) {
          ...AwardResultFullFragment
        }
      }
      ${AwardResultFullFragment}
    `,
    variables: { values },
  });
};

const refusalAwardCreate = async (values: IRefusalReasonRequest) => {
  return client.mutate<IEntityResponse<IRefusalReasonResponse>>({
    mutation: gql`
      mutation CandidacyRefusalCreateMutation($values: CandidacyRefusalCreateInput!) {
        entity: candidacyRefusalCreate(values: $values) {
          ...CandidacyRefusalFullFragment
        }
      }
      ${CandidacyRefusalFullFragment}
    `,
    variables: { values },
  });
};

const refusalAwardUpdate = async ({
  id,
  values,
}: {
  id: string;
  values: IRefusalReasonRequest;
}) => {
  return client.mutate<IEntityResponse<IRefusalReasonResponse>>({
    mutation: gql`
      mutation CandidacyRefusalPatchMutation($id: ID!, $changes: CandidacyRefusalPatchInput!) {
        entity: candidacyRefusalPatch(id: $id, changes: $changes) {
          ...CandidacyRefusalFullFragment
        }
      }
      ${CandidacyRefusalFullFragment}
    `,
    variables: { id, changes: values },
  });
};

const awardsService = {
  getAward,
  getAwards,
  createAward,
  updateAward,
  resultCreateAward,
  refusalAwardCreate,
  refusalAwardUpdateCreate: refusalAwardUpdate,
};
export default awardsService;
