import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import { combineSx } from '@/utils';

import { styles } from './styles';

import type { IBaseModal } from './types';

const BaseModal = ({ open, handleClose, children, className }: IBaseModal) => {
  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={combineSx(styles.root, className)}>{children}</Box>
    </Modal>
  );
};

export default BaseModal;
