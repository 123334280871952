import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ListProps, PhotoPreview } from '@/components';

import { list, listOfRows } from './constants';

import type { ITabProps } from '../types';
const BrandTab = ({ candidacy }: ITabProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={3} display="flex" justifyContent={{ xs: 'center', md: 'initial' }}>
        <PhotoPreview
          images={[candidacy?.Brand?.logoMedia?.largeThumbUrl]}
          width={267}
          height={178}
          alt={candidacy?.Brand?.name}
        />
      </Grid>
      <Grid item xs={12} md={9}>
        <Typography variant="h4">{candidacy?.Brand?.name}</Typography>
        <ListProps dataSource={candidacy} list={list} />
        <ListProps dataSource={candidacy} list={listOfRows()} />
      </Grid>
    </Grid>
  );
};

export default BrandTab;
