import { object, string } from 'yup';

import { selectionItemSchema } from '@/schemas';

export const schema = object().shape({
  productType: string().trim().min(1).max(125).required('Product Type is Required'),
  editorySectionId: selectionItemSchema.required('Food Category is Required'),
  pricePosition: selectionItemSchema.required('Price Position is Required'),
  brandType: selectionItemSchema.required('Brand Type is Required'),
});
