import { theme } from '@/constants/theme';

export const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
    height: '100vh',
    maxWidth: '1000px',
    margin: 'auto',
    gap: '15px',

    hr: {
      height: '100%',
    },
    '& > div': {
      width: '100%',
      m: '10px',
      padding: '30px 10px 20px',
      border: '0.5px solid #808080',
      borderRadius: '10px',

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },

    h4: {
      textTransform: 'none',
      textAlign: 'center',
    },
    h2: {
      display: 'none',
    },
    '& .logo, & .c32 > :first-child': {
      display: 'none !important',
    },
    h3: {
      textAlign: 'center',
    },
  },

  rowItem: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
  },

  logFrom: {
    '& > div': {
      [theme.breakpoints.down('md')]: {
        minWidth: '360px',
      },
    },
    '& button': {
      fontSize: '14px',
    },
    '& .c34-explanation': {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
  },
  showOnSmall: (val: boolean) => ({
    [theme.breakpoints.down('md')]: {
      display: (val ? 'block' : 'none') + ' !important',
    },
  }),
  hideOnBig: {
    [theme.breakpoints.up('md')]: {
      display: 'none !important',
    },
  },
} as const;
