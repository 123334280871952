import { createSlice } from '@reduxjs/toolkit';

import { getRelatedProducts } from './thunks';

import type IGoodsState from './types';
import type { IGoodResponse } from '@/dto';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IGoodsState = {
  loading: false,
  relatedGoods: [],
  selected: null,
};

export const goodsSlice = createSlice({
  name: 'goods',
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelected(state, { payload }: PayloadAction<IGoodResponse | null>) {
      state.selected = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRelatedProducts.pending, (state) => {
      state.loading = true;
      state.relatedGoods = [];
      state.selected = null;
    });
    builder.addCase(getRelatedProducts.fulfilled, (state, { payload }) => {
      state.relatedGoods = payload.data?.goodsDir?.edges.map((item) => item.node);
      state.loading = false;
    });
    builder.addCase(getRelatedProducts.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { resetState, setSelected } = goodsSlice.actions;

export default goodsSlice.reducer;
