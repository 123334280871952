import { addDays, differenceInDays } from 'date-fns';

import { SendUntilSelect } from '@/components/ui/forms/request/BusinessInfoForm';

const sendUntilMapper: Record<SendUntilSelect, number> = {
  [SendUntilSelect.High]: 30,
  [SendUntilSelect.Medium]: 40,
  [SendUntilSelect.Low]: 60,
};

const getSentUntilDateValue = (val: string): SendUntilSelect => {
  const dateDiff = differenceInDays(new Date(val), new Date());

  if (dateDiff <= sendUntilMapper[SendUntilSelect.High]) {
    return SendUntilSelect.High;
  } else if (dateDiff <= sendUntilMapper[SendUntilSelect.Medium]) {
    return SendUntilSelect.Medium;
  }

  return SendUntilSelect.Low;
};

const getDateFromSendUntilEnum = (val: SendUntilSelect, used?: string) => {
  if (!used) {
    return addDays(new Date(), sendUntilMapper[val]).toISOString();
  }

  const currentChoise = getSentUntilDateValue(used);
  return currentChoise == val ? used : addDays(new Date(), sendUntilMapper[val]).toISOString();
};

const dateUtil = {
  getSentUntilDateValue,
  getDateFromSendUntilEnum,
};

export default dateUtil;
