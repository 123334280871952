import { KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { IconButton, useMediaQuery } from '@mui/material';
import Drawer from '@mui/material/Drawer';

import EmptyToolbar from '@/components/layouts/MainLayout/components/EmptyToolbar';
import { theme } from '@/constants/theme';

import { stylesVariable } from './styles';

import type { IBaseRightDrawerProps } from './types';
import type { FC } from 'react';

const BaseRightDrawer: FC<IBaseRightDrawerProps> = ({ children, width, open, toggleOpen }) => {
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const styles = stylesVariable(open, width);

  return (
    <>
      <IconButton sx={styles.iconButton} onClick={toggleOpen}>
        {open ? <KeyboardDoubleArrowRight /> : <KeyboardDoubleArrowLeft />}
      </IconButton>
      <Drawer
        variant={isDesktop ? 'permanent' : 'temporary'}
        anchor="right"
        open={open}
        onClose={toggleOpen}
        sx={styles.drawer}
      >
        <EmptyToolbar />

        {children}
      </Drawer>
    </>
  );
};

export default BaseRightDrawer;
