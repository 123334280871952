import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import cart from '@/assets/images/cart.png';
import { BackgroundImage } from '@/components';
import { ActionsForm } from '@/components/ui/modals/DetailCandidacyModal/types';

import useTalkPropositionForm from './useTalkPropositionForm';

import type { ITalkPropositionProps } from './types';

const TalkPropositionForm = ({
  propositionId,
  awardCategoryId,
  onChangeActionsForm,
}: ITalkPropositionProps) => {
  const { t } = useTranslation();

  const { isSubmitting, handelSubmit } = useTalkPropositionForm({
    propositionId,
    awardCategoryId,
    onChangeActionsForm,
  });
  return (
    <Grid container spacing={1} maxWidth={500} mx="auto">
      <Grid item xs={12}>
        <Typography component="h4" textAlign="center" variant="h4" mb={1}>
          {t("Well, looks like there's something interesting for you")}
        </Typography>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" justifyContent="center">
        <BackgroundImage src={cart} height={120} width={180} sx={{ mb: 1 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography textAlign="center">
          {t('Click the button to confirm your desire to speak with the company representatives')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          color="success"
          onClick={handelSubmit}
        >
          {t('Introduce me to the company')}
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="inherit"
          disabled={isSubmitting}
          onClick={onChangeActionsForm(ActionsForm.main)}
        >
          {t("I'll think about it")}
        </Button>
      </Grid>
    </Grid>
  );
};

export default TalkPropositionForm;
