import { gql } from '@apollo/client';

import { client } from '@/utils';

import type {
  ICompanyYearsCreateRequest,
  ICompanyYearsPatchRequest,
  ICompanyYearsResponse,
} from '@/dto';

const createCompanyYears = async (values: ICompanyYearsCreateRequest) => {
  return client.mutate<ICompanyYearsResponse>({
    mutation: gql`
      mutation CompanyYearsCreateMutation($values: CompanyYearCreateInput!) {
        results: companyYearCreate(values: $values) {
          id
          revenue
          numEmployees
          year
        }
      }
    `,
    variables: { values },
  });
};

const updateCompanyYears = async ({ id, changes }: ICompanyYearsPatchRequest) => {
  return client.mutate<ICompanyYearsResponse>({
    mutation: gql`
      mutation CompanyYearsPatchMutation($id: ID!, $changes: CompanyYearPatchInput!) {
        results: companyYearPatch(id: $id, changes: $changes) {
          id
          revenue
          numEmployees
          year
        }
      }
    `,
    variables: { id, changes },
  });
};

const companyYearsService = {
  createCompanyYears,
  updateCompanyYears,
};
export default companyYearsService;
