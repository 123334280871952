import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { candidacySelectedState } from '@/redux/awards/selectors';
import { changeSelectedTab, setCandidacy } from '@/redux/awards/slice';

import { tabs } from './constants';
import { ActionsForm, DetailCandidacyModalTab } from './types';

import type { IDetailCandidacyModalProps } from './types';

const useDetailCandidacyModal = ({ order, orderBy }: IDetailCandidacyModalProps) => {
  const dispatch = useDispatch();
  const { prev, current, next, selectedTab, isPrivateLabel } = useSelector(
    candidacySelectedState(order, orderBy),
  );
  const [actionsForm, setActionsForm] = useState(ActionsForm.main);

  const tab = useMemo(() => tabs.find((item) => item.label === selectedTab), [selectedTab]);

  const filteredTabs = useMemo(
    () =>
      isPrivateLabel ? tabs.filter(({ label }) => label !== DetailCandidacyModalTab.Brand) : tabs,
    [isPrivateLabel],
  );

  const handleChangeTab = useCallback(
    (index: DetailCandidacyModalTab, form?: ActionsForm) => () => {
      dispatch(changeSelectedTab(index));
      if (form) {
        setActionsForm(form);
      }
    },
    [dispatch],
  );

  const handleChangeActionsForm = useCallback(
    (form: ActionsForm) => () => {
      setActionsForm(form);
    },
    [],
  );

  const toggleCandidacyRow = useCallback(
    (id: string | null) => () => {
      dispatch(setCandidacy({ candidacyId: id }));
    },
    [dispatch],
  );

  return {
    tab,
    tabs: filteredTabs,
    prev,
    current,
    next,
    toggleCandidacyRow,
    selectedTab,
    handleChangeTab,
    actionsForm,
    handleChangeActionsForm,
  };
};

export default useDetailCandidacyModal;
