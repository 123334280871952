import {
  AwardGoodUnit,
  AwardPaymentCurrency,
  AwardPurchasingType,
  AwardGoodUnitExternal,
} from '@/enums';
import { UnitType } from '@/enums/award/award-unit-type-section';
import { enumUtil } from '@/utils';

export const purchasingTypeOptions: { value: AwardPurchasingType; label: string }[] = [
  { value: AwardPurchasingType.IN_AND_OUT, label: 'In & Out' },
  { value: AwardPurchasingType.ONGOING, label: 'Ongoing' },
];

export const goodUnitOptions: {
  value: AwardGoodUnit | AwardGoodUnitExternal;
  label: string;
  type: UnitType;
}[] = [
  { value: AwardGoodUnit.GRAMS, label: 'Grams (g)', type: UnitType.Origin },
  { value: AwardGoodUnit.MILLIGRAMS, label: 'Milligrams (mg)', type: UnitType.Origin },
  { value: AwardGoodUnit.MILLILITER, label: 'Milliliter (ml)', type: UnitType.Origin },

  {
    value: AwardGoodUnitExternal.FLUID_OUNCE,
    label: 'Fluid Ounce (fl oz)',
    type: UnitType.External,
  },
  {
    value: AwardGoodUnitExternal.CUP,
    label: 'Cup (c)',
    type: UnitType.External,
  },
  {
    value: AwardGoodUnitExternal.PINT,
    label: 'Pint (pt)',
    type: UnitType.External,
  },
  {
    value: AwardGoodUnitExternal.QUART,
    label: 'Quart (qt)',
    type: UnitType.External,
  },
  {
    value: AwardGoodUnitExternal.GALLON,
    label: 'Gallon (gal)',
    type: UnitType.External,
  },
  {
    value: AwardGoodUnitExternal.OUNCE,
    label: 'Ounce (oz)',
    type: UnitType.External,
  },
  {
    value: AwardGoodUnitExternal.POUND,
    label: 'Pound (lb)',
    type: UnitType.External,
  },
];

export const boolSelectOptions: { value: boolean | null; label: string }[] = [
  { value: false, label: 'No' },
  { value: true, label: 'Yes' },
];

export const currencyOptions = enumUtil.enumToList(AwardPaymentCurrency);
