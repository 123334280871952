import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { NoItemsReceivedCard } from '@/components/ui/cards';

import useAnnualNumberList from './useAnnualNumberList';

const AnnualNumberList = ({ onCancel }: { onCancel: () => void }) => {
  const { headerItems, userYears, cellItems, onSelectAction, canCreateNew } = useAnnualNumberList();
  const { t } = useTranslation();

  return (
    <Grid container spacing={2} sx={{ textAlign: 'center' }}>
      {headerItems.map((head) => (
        <Grid
          item
          xs={3}
          key={head}
          component={Box}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography>{t(head)}</Typography>
        </Grid>
      ))}
      <Grid item xs={12} sx={{ mt: 1 }}>
        <Divider />
      </Grid>

      {!userYears.length && (
        <Grid item xs={12}>
          <NoItemsReceivedCard sx={{ mt: 2 }} title="No Annual Number has been created yet" />
        </Grid>
      )}
      {userYears.map((numb) => (
        <>
          {cellItems.map((cell) => (
            <Grid item xs={3} key={cell + numb.id}>
              <Typography>{t(numb[cell])}</Typography>
            </Grid>
          ))}
          <Grid item xs={3}>
            <Button size="small" onClick={onSelectAction(numb)}>
              Edit
            </Button>
          </Grid>
        </>
      ))}
      <Grid item xs={12}>
        <Box display="flex" gap={2} justifyContent="center" flexWrap="wrap">
          {canCreateNew.map((item) => (
            <Button
              onClick={onSelectAction({ year: item })}
              variant="outlined"
            >{`${t('Create for')} ${item} ${t('year')}`}</Button>
          ))}
        </Box>
      </Grid>

      <IconButton onClick={onCancel} sx={{ position: 'absolute', right: 3, top: 3 }}>
        <CloseIcon />
      </IconButton>
    </Grid>
  );
};

export default AnnualNumberList;
