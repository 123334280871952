export enum MediaType {
  LOGO = 'LOGO',
  GOOD_IMAGE = 'GOOD_IMAGE',
  ICON = 'ICON',
  PDF_WEB = 'PDF_WEB',
  PDF_GENERIC = 'PDF_GENERIC',
  BANNER_FULL = 'BANNER_FULL',
  BANNER_HALF = ' BANNER_HALF',
  BANNER_LEADERBOARD = 'BANNER_LEADERBOARD',
  BANNER_POPUP = 'BANNER_POPUP',
  NEWS = 'NEWS',
  MAGAZINE_COVER = 'MAGAZINE_COVER',
  LOGO_PRINT = 'LOGO_PRINT',
  GOOD_PRINT_IMAGE = 'GOOD_PRINT_IMAGE',
  GALLERY_GENERIC = 'GALLERY_GENERIC',
  MAIL_IMAGE = 'MAIL_IMAGE',
}
