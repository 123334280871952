import { Button, Divider, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { Widget } from '@typeform/embed-react';
import { useCallback, useState } from 'react';

import { combineSx } from '@/utils';

import { styles } from './styles';

import type { PropsWithChildren } from 'react';

enum SelectedAuthTab {
  Login,
  Register,
}

const DefaultLayout = ({ children }: PropsWithChildren<{}>) => {
  const [selectedTab, setSelectedTab] = useState(SelectedAuthTab.Login);

  const setTab = useCallback((val: SelectedAuthTab) => () => setSelectedTab(val), []);

  return (
    <Box component="main" sx={styles.root}>
      <Grid container justifyContent="center">
        {/* <Grid item md={6} xs={12} sx={styles.showOnSmall(false)}></Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={styles.showOnSmall(selectedTab == SelectedAuthTab.Login)}
        ></Grid> */}

        <Grid item xs={12} mt={3} p={1}>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            height="100%"
            sx={combineSx(
              { flexGrow: 1 },
              styles.logFrom,
              styles.showOnSmall(selectedTab == SelectedAuthTab.Register),
            )}
          >
            <Typography variant="h4">Register</Typography>
            <Widget id="w5TeMOMF" style={{ flexGrow: 1, height: '600px' }} className="my-form" />
          </Box>
          <Divider sx={{ display: { md: 'block', xs: 'none' } }} orientation="vertical" />
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            sx={combineSx(styles.showOnSmall(selectedTab == SelectedAuthTab.Login))}
          >
            <Typography variant="h4">Login</Typography>
            <Box sx={styles.logFrom} mt={{ md: 6 }}>
              {children}
            </Box>
          </Box>
        </Grid>

        <Grid item sx={styles.hideOnBig}>
          {selectedTab == SelectedAuthTab.Register && (
            <Button onClick={setTab(SelectedAuthTab.Login)} variant="outlined">
              Sign In
            </Button>
          )}
          {selectedTab == SelectedAuthTab.Login && (
            <Button onClick={setTab(SelectedAuthTab.Register)} variant="outlined">
              Sign Up
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DefaultLayout;
