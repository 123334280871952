import Grid from '@mui/material/Grid';

import { DeclinePropositionForm } from '@/components/ui/forms';

import type { ITabProps } from '@/components/ui/modals/DetailCandidacyModal/components/types';

const DeclineFrom = ({ onChangeActionsForm }: ITabProps) => {
  return (
    <Grid item sm={12}>
      <DeclinePropositionForm onChangeActionsForm={onChangeActionsForm} />
    </Grid>
  );
};

export default DeclineFrom;
