// import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import InfoIcon from '@mui/icons-material/Info';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import { styles } from '@/components/layouts/MainLayout/components/Menu/styles';

import type { TFunction } from 'i18next';

export const menuList = (t: TFunction) => [
  { title: t('home'), icon: <HomeIcon sx={styles.icon} />, path: '/' },
  {
    title: t('parchasing-request'),
    icon: <ShoppingCartIcon sx={styles.icon} />,
    path: '/purchasing-request',
  },
  // { title: t('new-ideas'), icon: <ContentPasteIcon sx={styles.icon} />, path: '/' },
  { title: t('faq'), icon: <InfoIcon sx={styles.icon} />, path: '/faq' },
  {
    title: t('Contact Support'),
    icon: <HelpIcon sx={styles.icon} />,
    sx: { marginTop: 'auto' },
    path: '#',
    onClick: () => {
      window.location.assign('mailto:support@gruppofood.com?subject=I Need Help');
    },
  },
];
