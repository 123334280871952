import { Checkbox, FormGroup } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { ActionsForm } from '@/components/ui/modals/DetailCandidacyModal/types';

import { list } from './constants';
import { schema } from './schema';
import { styles } from './styles';
import useDeclinePropositionForm from './useDeclinePropositionForm';

import type { IDeclinePropositionProps } from './types';

const DeclinePropositionForm = ({ onChangeActionsForm }: IDeclinePropositionProps) => {
  const { t } = useTranslation();
  const { handlerSubmit, wasRefused, initialState } = useDeclinePropositionForm({
    onChangeActionsForm,
  });
  return (
    <Formik
      initialValues={initialState}
      validationSchema={schema}
      onSubmit={handlerSubmit}
      validateOnMount
    >
      {({ values, errors, isSubmitting, setFieldValue }) => (
        <Form>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography component="h4" mb={1} variant="h4">
                {t('Tell us why it doesn`t fit you well')}:
              </Typography>
              <Typography>{t('Select at least one reason')}</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <FormGroup
                // value={values.reasonRadios}
                // onChange={(_, value) => setFieldValue('reasonRadios', value)}
                >
                  {list.map((item) => (
                    <FormControlLabel
                      key={item.label}
                      sx={styles.checkbox}
                      value={t(item.label)}
                      control={
                        <Checkbox
                          checked={values.reasonRadios[item.key]}
                          onClick={() =>
                            setFieldValue('reasonRadios', {
                              ...values.reasonRadios,
                              [item.key]: !values.reasonRadios[item.key],
                            })
                          }
                        />
                      }
                      label={t(item.label)}
                    />
                  ))}
                </FormGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                maxRows={4}
                minRows={4}
                value={values.text}
                fullWidth
                label={t('Other')}
                name="text"
                disabled={isSubmitting}
                onChange={(e) => setFieldValue('text', e.target.value)}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="contained"
                onClick={onChangeActionsForm(ActionsForm.main)}
              >
                {t('Cancel')}
              </Button>
              <Button
                type="submit"
                disabled={isSubmitting || Boolean(errors.text || errors.reasonRadios)}
                variant="contained"
                color="error"
              >
                {wasRefused ? t('Change Decline Reason') : t('Decline this Proposition')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default DeclinePropositionForm;
