import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import get from 'lodash.get';
import { useTranslation } from 'react-i18next';

import { Order } from '@/enums';
import { combineSx } from '@/utils';

import { cells } from './constants';
import { styles } from './styles';
import useRelatedProductsTable from './useRelatedProductsTable';

import type { IRelatedProductsTableProps } from './types';

const RelatedProductsTable = ({ goods }: IRelatedProductsTableProps) => {
  const { t } = useTranslation();
  const {
    rows,
    order,
    orderBy,
    page,
    rowsPerPage,
    handleSort,
    handleChangePage,
    handleChangeRowsPerPage,
    // handleViewDetails,
  } = useRelatedProductsTable({ goods });

  return (
    <Paper sx={styles.paper}>
      <Box sx={{ position: 'relative', minHeight: 340 }}>
        <TableContainer sx={styles.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {cells.map(({ key, label, th, align, sort }) => (
                  <TableCell
                    {...th}
                    align={align}
                    key={key}
                    sortDirection={orderBy === key ? order : false}
                    sx={combineSx(styles.th, th?.sx)}
                  >
                    {sort ? (
                      <TableSortLabel
                        active={orderBy === key}
                        direction={orderBy === key ? order : Order.Asc}
                        onClick={() => handleSort(key)}
                      >
                        {t(label)}
                      </TableSortLabel>
                    ) : (
                      t(label)
                    )}
                  </TableCell>
                ))}
                {/* <TableCell align="center" sx={styles.th}>
                  {t('Actions')}
                </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id} sx={{ cursor: 'pointer' }}>
                    {cells.map(({ td, align, key, format }) => (
                      <TableCell {...td} key={`${row.id}_${key}`} align={align}>
                        {format?.(row) ?? get(row, key, null)}
                      </TableCell>
                    ))}
                    {/* <TableCell align="center">
                      <Button
                        sx={styles.actionButton}
                        variant="text"
                        disableRipple
                        disableElevation
                        disableFocusRipple
                        disableTouchRipple
                        onClick={handleViewDetails(row)}
                      >
                        {t('See Detail')}
                      </Button>
                    </TableCell> */}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <TablePagination
        rowsPerPageOptions={[50, 100, 250]}
        sx={styles.pagination}
        component="div"
        count={goods.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  );
};

export default RelatedProductsTable;
