import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ImageLoader, ListProps } from '@/components';
import {
  list,
  listCandidacy,
} from '@/components/ui/modals/DetailCandidacyModal/components/PropositionTab/constants';

import styles from '../styles.module.scss';

import type { IPropositionPage } from './types';

const PropositionPage = ({ candidacy, award }: IPropositionPage) => {
  const { t } = useTranslation();
  const questions = useMemo(() => {
    return Array.from({ length: 10 }, (_, index) => ({
      question: award?.[`description${index + 1}Label`] ?? null,
      answer: candidacy?.[`description${index + 1}`] ?? null,
    })).filter(({ question, answer }) => question && answer);
  }, [candidacy, award]);

  return (
    <div className={[styles.page, 'page'].join(' ')}>
      <div className={styles.row}>
        <div className={[styles.col12, styles.header].join(' ')}>
          <h1 className={[styles.textCenter, styles.textMute, styles['bt-3']].join(' ')}>
            Proposition
          </h1>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col2}>
          <ImageLoader
            className={styles.image}
            alt={candidacy?.name}
            src={candidacy?.featuredImageMedia?.mediumThumbUrl}
          />
        </div>
        <div className={styles.col10}>
          <h1 className={styles.title}>{candidacy.name}</h1>
          <div className={[styles.list, styles['mt-3'], styles.flex].join(' ')}>
            <ListProps dataSource={candidacy.goods[0]} list={list(t, true)} />
            <ListProps dataSource={candidacy} list={listCandidacy} />
          </div>
        </div>
      </div>

      {candidacy?.description1 && (
        <div className={styles.row}>
          <div className={styles.col12}>
            <h4>Description:</h4>
            <div
              className={styles.text}
              dangerouslySetInnerHTML={{ __html: candidacy.description1 }}
            />
          </div>
        </div>
      )}

      <div className={[styles.row, styles['mt-3']].join(' ')}>
        <div className={[styles.col12, styles.text, styles.twoRows].join(' ')}>
          {questions.map(({ question, answer }, index) => (
            <div key={index}>
              <h6>
                # {index + 1} {question}
              </h6>
              <div dangerouslySetInnerHTML={{ __html: answer }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PropositionPage;
