import { gql } from '@apollo/client';

import { EditorialSectionFullFragment } from './EditorialSectionFullFragment';

export const GoodCardFragment = gql`
  fragment GoodCardFragment on Good {
    id
    localId
    slug
    name
    formats {
      id
      cuMesureUnit
      cuMesureValue
      cuPerSellingPack
    }
    shelfLifeLength
    featuredImageMedia {
      id
      name
      smallThumbUrl
      mediumThumbUrl
      largeThumbUrl
    }
    goodFeatureCertifications {
      id
      name
    }
    GoodClass {
      id
      name
      preservation
      editorialSections {
        ...EditorialSectionFullFragment
      }
    }
    Brand {
      id
      name
      logoMedia {
        id
        name
        smallThumbUrl
        mediumThumbUrl
        largeThumbUrl
      }
      Company {
        id
        name
        slug
        currentCompanyLevels {
          id
          level
        }
        companyCertifications {
          id
          name
        }
        logoMedia {
          id
          name
          smallThumbUrl
          mediumThumbUrl
          largeThumbUrl
        }
      }
    }
    description
    descriptionLong
  }
  ${EditorialSectionFullFragment}
`;
