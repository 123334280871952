export enum AwardPaymentCurrency {
  ARS = 'ARS',
  AUD = 'AUD',
  THB = 'THB',
  BRL = 'BRL',
  CAD = 'CAD',
  XOF = 'XOF',
  XAF = 'XAF',
  CLP = 'CLP',
  COP = 'COP',
  CZK = 'CZK',
  DKK = 'DKK',
  VND = 'VND',
  EGP = 'EGP',
  EUR = 'EUR',
  HUF = 'HUF',
  HKD = 'HKD',
  UAH = 'UAH',
  ISK = 'ISK',
  INR = 'INR',
  AOA = 'AOA',
  LBP = 'LBP',
  MYR = 'MYR',
  MXN = 'MXN',
  MAD = 'MAD',
  BOV = 'BOV',
  NGN = 'NGN',
  ILS = 'ILS',
  NZD = 'NZD',
  NOK = 'NOK',
  PEN = 'PEN',
  UYU = 'UYU',
  GBP = 'GBP',
  QAR = 'QAR',
  RON = 'RON',
  IDR = 'IDR',
  RUB = 'RUB',
  SAR = 'SAR',
  RSD = 'RSD',
  SEK = 'SEK',
  CHF = 'CHF',
  TND = 'TND',
  TRY = 'TRY',
  AED = 'AED',
  CLF = 'CLF',
  USD = 'USD',
  JPY = 'JPY',
  CNY = 'CNY',
}
