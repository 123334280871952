import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PublicIcon from '@mui/icons-material/Public';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';

import type { ISocialButtonsProps } from './types';

const SocialButtons = ({ instagramUrl, facebookUrl, websiteUrl }: ISocialButtonsProps) => {
  if (!instagramUrl && !facebookUrl && !websiteUrl) {
    return null;
  }
  return (
    <Box display="inline-flex" component="span">
      {instagramUrl && (
        <Tooltip title="Instagram Profile">
          <IconButton aria-label="instagram" target="_blank" href={instagramUrl}>
            <InstagramIcon />
          </IconButton>
        </Tooltip>
      )}
      {facebookUrl && (
        <Tooltip title="Facebook Profile">
          <IconButton aria-label="facebook" target="_blank" href={facebookUrl}>
            <FacebookIcon />
          </IconButton>
        </Tooltip>
      )}
      {websiteUrl && (
        <Tooltip title="Website">
          <IconButton aria-label="website" target="_blank" href={websiteUrl}>
            <PublicIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default SocialButtons;
