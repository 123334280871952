import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import type { INoItemsReceivedCardProps } from './type';

const NoItemsReceivedCard = ({ title, text, sx }: INoItemsReceivedCardProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={sx}
      mt={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <WarningIcon fontSize="large" />
      <Typography variant="h5" mt={1}>
        {t(title)}
      </Typography>
      {text && (
        <Typography variant="subtitle1" mt={1}>
          {t(text)}
        </Typography>
      )}
    </Box>
  );
};

export default NoItemsReceivedCard;
