import { gql } from '@apollo/client';

import { GoodCardFragment, GoodShortFragment } from '@/qraphql/fragments';
import { client } from '@/utils';

import type { IGoodResponse } from '@/dto';

const getRelatedProducts = async (values: { companies: { id: string }[] }) => {
  return client.query<{ goodsDir: { edges: { node: IGoodResponse }[] } }>({
    fetchPolicy: 'no-cache',
    query: gql`
      query relatedProductsLowerQuery($filter: GoodsFilterType!) {
        goodsDir(filter: $filter, first: 50) {
          edges {
            node {
              ...GoodCardFragment
            }
          }
          total
        }
      }
      ${GoodCardFragment}
    `,
    variables: { filter: values },
  });
};

const getListOfRelatedProducts = async (values: { companies: { id: string }[] }) => {
  return client.query<{ goodsDir: { edges: { node: IGoodResponse }[] } }>({
    fetchPolicy: 'no-cache',
    query: gql`
      query relatedProductsLowerQuery($filter: GoodsFilterType!) {
        goodsDir(filter: $filter, first: 1000) {
          edges {
            node {
              ...GoodShortFragment
            }
          }
          total
        }
      }
      ${GoodShortFragment}
    `,
    variables: { filter: values },
  });
};

const goodsService = {
  getRelatedProducts,
  getListOfRelatedProducts,
};
export default goodsService;
