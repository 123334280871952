import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useEffect, useMemo } from 'react';

import { ActionsForm } from '@/components/ui/modals/DetailCandidacyModal/types';

import { forms } from './constants';

import type { ITabProps } from '../types';

const ActionsTab = ({ candidacy, actionsForm, onChangeActionsForm }: ITabProps) => {
  useEffect(() => {
    return onChangeActionsForm(ActionsForm.main);
  }, [onChangeActionsForm]);

  const Form = useMemo(() => forms[actionsForm], [actionsForm]);

  return (
    <Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        <Grid container spacing={2} maxWidth={600}>
          <Form
            candidacy={candidacy}
            actionsForm={actionsForm}
            onChangeActionsForm={onChangeActionsForm}
          />
        </Grid>
      </Box>
    </Box>
  );
};

export default ActionsTab;
