import { Typography } from '@mui/material';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { setCandidacy } from '@/redux/awards/slice';

import type { ILinkCellProps } from './types';

const LinkCell = ({ label, id }: ILinkCellProps) => {
  const dispatch = useDispatch();
  const handleSelectRow = useCallback(() => {
    dispatch(setCandidacy({ candidacyId: id }));
  }, [dispatch, id]);
  return (
    <Typography onClick={handleSelectRow} sx={{ textDecoration: 'underline', cursor: 'pointer' }}>
      {label}
    </Typography>
  );
};

export default LinkCell;
