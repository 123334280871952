import { boolean, object, string } from 'yup';

import { RefusalTypeFlags } from '@/enums';

export const schema = object().shape({
  text: string().trim().nullable(),
  reasonRadios: object()
    .shape({
      [RefusalTypeFlags.KNOWN_COMPANY]: boolean().optional(),
      [RefusalTypeFlags.NON_COMPETITIVE]: boolean().optional(),
      [RefusalTypeFlags.NON_DIFFERENTIATED]: boolean().optional(),
    })
    .test(
      'reasonRadios-check',
      'If text is empty, at least one reason must be true',
      function (value) {
        const { text } = this.parent;
        if (!text) {
          return Object.values(value).some((v) => v === true);
        }
        return true;
      },
    ),
});
