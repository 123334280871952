import { tableCellClasses, tablePaginationClasses } from '@mui/material';

import { theme } from '@/constants/theme';

export const styles = {
  paper: { width: '100%' },
  buttonBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    mt: 4,
    mb: 2,
  },
  button: {
    textTransform: 'initial',
    fontWeight: 'bold',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  actionButton: {
    textTransform: 'initial',
    color: theme.palette.common.black,
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  container: {
    overflow: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  th: {
    minWidth: 165,
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.grey[100],
    },
  },
  pagination: {
    [`& .${tablePaginationClasses.displayedRows}`]: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },
} as const;
