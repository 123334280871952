import { injectCSS, defaultTheme as theme } from '@food/css-manager';
import { init } from '@food/tracking';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';

// eslint-disable-next-line
import store from './redux/store';

import './utils/i18n.util';
import { App } from '@/components';
import { config } from '@/utils';

import 'react-toastify/dist/ReactToastify.css';
import 'react-photo-view/dist/react-photo-view.css';

init({
  baseUrl: config.foodApi.endpoint.tracking,
  bufferLength: 1,
  targetSite: config.foodApi.targetSite,
});

injectCSS({ theme });

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
