export const styles = {
  root: {
    '& label': {
      textDecoration: 'underline',
      cursor: 'pointer',
      mb: 3,
    },
    '& input': {
      opacity: 0,
      position: 'absolute',
      zIndex: -1,
    },
  },
} as const;
