import { createSlice } from '@reduxjs/toolkit';

import type IAppState from './types';

const initialState: IAppState = {
  isMenuOpen: false,
  imageLoading: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleMenu(state) {
      state.isMenuOpen = !state.isMenuOpen;
    },
    addImageLoader(state, { payload }) {
      if (!state.imageLoading) {
        state.imageLoading = [payload];
        return;
      }
      state.imageLoading = [...state.imageLoading, payload];
    },
    removeImageLoader(state, { payload }) {
      const indexToDelete = state.imageLoading?.findIndex((item) => item == payload);
      state.imageLoading = state.imageLoading?.filter((_, ind) => ind != indexToDelete);
    },
    resetImageLoader(state) {
      state.imageLoading = null;
    },
  },
});

export const { toggleMenu, addImageLoader, removeImageLoader, resetImageLoader } = appSlice.actions;

export default appSlice.reducer;
