import { useEffect, useMemo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AwardBrandType } from '@/enums';
import { imageLoadingState } from '@/redux/app/selectors';
import { resetImageLoader } from '@/redux/app/slice';
import { awardSelectedState } from '@/redux/awards/selectors';

import type { IProposalPdfProps } from './types';

const useProposalPdf = (onClose: IProposalPdfProps['onClose']) => {
  const { selected } = useSelector(awardSelectedState);
  const imageLoadings = useSelector(imageLoadingState);
  const dispatch = useDispatch();
  const ref = useRef(null);

  const isPrivateLabel = useMemo(
    () => selected.brandType === AwardBrandType.PRIVATE_LABEL,
    [selected.brandType],
  );

  useEffect(() => {
    if (imageLoadings !== null && imageLoadings === false) {
      window.print();
    }
    window.addEventListener('afterprint', () => {
      onClose(false)();
    });
  }, [dispatch, imageLoadings, onClose]);

  useEffect(() => {
    return () => {
      dispatch(resetImageLoader());
    };
  }, [dispatch]);

  return { ref, isPrivateLabel, selected };
};

export default useProposalPdf;
