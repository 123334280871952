import type { Order } from '@/enums';
import type { ElementType } from 'react';

export enum ActionsForm {
  main,
  decline,
  talk,
}

export enum DetailCandidacyModalTab {
  Company = 'Company',
  Proposition = 'Proposition',
  Brand = 'Brand',
  RelatedProducts = 'Related Products',
  Actions = 'Actions',
}

export interface ITab {
  label: DetailCandidacyModalTab;
  Icon: ElementType;
  Tab: ElementType;
}

export interface IDetailCandidacyModalProps {
  order: Order;
  orderBy: string | null;
}
