import en_US from '@/static/i18n/en.json';

const labels = {
  ...en_US.translation,
};

const notTranslated = [];
function getNotTranslated() {
  return notTranslated.map((id) => `'${id}': ''`).join(',\n');
}
if (typeof window !== 'undefined') {
  // the variable is defined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).getNotTranslated = getNotTranslated;
}

function t(...stringIds) {
  let i = 0;
  for (i; i < stringIds.length; i++) {
    const stringId = stringIds[i];

    if (!stringId) {
      continue;
    }

    const id = typeof stringId === 'string' ? stringId : stringId[0];

    if (id in labels) {
      return labels[id];
    }

    // console.warn(`String "${id}" not translated!`);
    if (notTranslated.indexOf(id) === -1) {
      notTranslated.push(id);
    }
    if (i === stringIds.length - 1) {
      return id;
    }
  }
}

export default t;
