import { createAsyncThunk } from '@reduxjs/toolkit';

import { companyYearsService } from '@/services';
import { handleThunkApiError } from '@/utils';

export const createCompanyYears = createAsyncThunk(
  'company/createCompanyYears',
  handleThunkApiError(companyYearsService.createCompanyYears),
);

export const updateCompanyYears = createAsyncThunk(
  'company/updateCompanyYears',
  handleThunkApiError(companyYearsService.updateCompanyYears),
);
