import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '@/redux/store';

export const userStateSelector = ({ user }: RootState) => user;

export const userDataState = createSelector(userStateSelector, (state) => state.user);

export const usersRetailerState = createSelector(
  userStateSelector,
  (state) => state.user?.managedByUsers?.find((item) => item.Retailer.id)?.Retailer,
);

export const selectedAnnualNumberState = createSelector(
  userStateSelector,
  (state) => state.selectedAnnualNumber,
);
