import { gql } from '@apollo/client';

import { client } from '@/utils';

import type { IEntityResponse, IRetailerResponse } from '@/dto';

const updateRetailerMedia = async ({
  id,
  changes,
}: {
  changes: { logoMedia: { id: string } };
  id: string;
}) => {
  return client.mutate<IEntityResponse<IRetailerResponse>>({
    mutation: gql`
      mutation retailerPatchMutation($id: ID!, $changes: RetailerPatchInput!) {
        entity: retailerPatch(id: $id, changes: $changes) {
          id
        }
      }
    `,
    variables: { changes, id },
  });
};

const retailerService = {
  updateRetailerMedia,
};

export default retailerService;
