import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { AutocompleteInput, TextInput } from '@/components/ui/inputs';

import { RequestActionButtons } from '../components';

import { brandTypeOptions, pricePositionOptions } from './constants';
import { schema } from './schema';
import useGeneralInformationForm from './useGeneralInformationForm';

import type { IRequestFormProps } from '../types';

const GeneralInformationForm = (props: IRequestFormProps) => {
  const {
    initialValues,
    retailerData,
    onSubmit,
    onEditorialInputChange,
    isLoading,
    editorialSectionsOptions,
  } = useGeneralInformationForm();
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema}>
      {({ values }) => (
        <Form>
          <Field
            as={TextInput}
            required
            label={t('Product Type Input')}
            name="productType"
            helperText={`PR name will be: "${retailerData?.Country.name || ''} Retailer - ${values.productType || '___'} - ${new Date().getFullYear()}"`}
          />

          <Field
            as={AutocompleteInput}
            name="editorySectionId"
            label={t('Food Category')}
            placeholder={t('Enter A Word To Search')}
            required
            autoCompleteProps={{
              options: editorialSectionsOptions,
              loading: isLoading,
              onInputChange: (_, v: string) => onEditorialInputChange(v),
            }}
          />

          <Field
            as={AutocompleteInput}
            name="pricePosition"
            label={t('Price Positioning')}
            required
            autoCompleteProps={{ options: pricePositionOptions }}
          />

          <Field
            as={AutocompleteInput}
            label={t('Brand / Private Label')}
            name="brandType"
            required
            autoCompleteProps={{ options: brandTypeOptions }}
          />
          <RequestActionButtons {...props} />
        </Form>
      )}
    </Formik>
  );
};

export default GeneralInformationForm;
