import { theme } from '@/constants/theme';

export const styles = {
  menuItem: {
    minWidth: '150px',
    display: 'flex',
    gap: theme.spacing(1),
    color: '#54667A',
  },
} as const;
