import { gql } from '@apollo/client';

import { MediaBaseFragment } from '@/qraphql';
import { client } from '@/utils';

import type { IEntityResponse, IUserResponse } from '@/dto';

const getUserAccount = async () => {
  return client.query<IEntityResponse<IUserResponse>>({
    fetchPolicy: 'no-cache',
    query: gql`
      query GetCurrentUserData {
        entity: currentUser {
          id
          role
          email
          status
          workEmail
          managedByUsers {
            id
            capabilities
            Retailer {
              id
              name
              StateProvince {
                id
                name
                region
                code
              }
              Country {
                id
                name
                area
              }
              companyYears {
                id
                revenue
                numEmployees
                year
              }
              logoMedia {
                ...MediaBaseFragment
              }
              city
              address1
              address2
            }
          }
        }
      }
      ${MediaBaseFragment}
    `,
  });
};

const userService = {
  getUserAccount,
};
export default userService;
