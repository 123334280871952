import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';

import type { ButtonProps } from '@mui/material';

const LinkButton = ({ children, href, ...rest }: ButtonProps) => (
  <Link to={href}>
    <Button {...rest}>{children}</Button>
  </Link>
);

export default LinkButton;
