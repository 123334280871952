import { ThemeProvider, CssBaseline } from '@mui/material';

import 'react-toastify/dist/ReactToastify.css';

import { Routes } from '@/components/common';
import { theme } from '@/constants/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes />
    </ThemeProvider>
  );
}

export default App;
