import { AwardBrandType, AwardPricePosition } from '@/enums';

export const pricePositionOptions: { value: AwardPricePosition; label: string }[] = [
  { value: AwardPricePosition.DISCOUNT, label: 'Discount' },
  { value: AwardPricePosition.MINSTREAM, label: 'Mainstream' },
  { value: AwardPricePosition.PREMIUM, label: 'Premium' },
  // { value: AwardPricePosition.LUXURY, label: 'Luxury' },
];

export const brandTypeOptions: { value: AwardBrandType; label: string }[] = [
  { value: AwardBrandType.BRAND, label: 'Brand' },
  { value: AwardBrandType.PRIVATE_LABEL, label: 'Private Label' },
];
