import { gql } from '@apollo/client';

import { MediaBaseFragment } from '@/qraphql/fragments/MediaBaseFragment';
import { client } from '@/utils';

import type { IAwardCategoryResponse, IAwardCategoryCreateInput, IEntityResponse } from '@/dto';

const createCategory = async (values: IAwardCategoryCreateInput) => {
  return client.mutate<IEntityResponse<IAwardCategoryResponse>>({
    mutation: gql`
      mutation AwardCategoryCreateMutation($values: AwardCategoryCreateInput!) {
        entity: awardCategoryCreate(values: $values) {
          id
          localId
          name
          slug
          description
          Media {
            ...MediaBaseFragment
          }
          color
          giftUrl
        }
      }
      ${MediaBaseFragment}
    `,
    variables: { values },
  });
};
const categoryService = {
  createCategory,
};
export default categoryService;
