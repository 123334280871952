import { createSlice } from '@reduxjs/toolkit';

import { getUserAccount } from './thunks';

import type { IselectedAnnualNumber } from './types';
import type IUserState from './types';
import type { PayloadAction } from '@reduxjs/toolkit';

const initialState: IUserState = {
  user: null,
  selectedAnnualNumber: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setselectedAnnualNumber(state, { payload }: PayloadAction<IselectedAnnualNumber | null>) {
      state.selectedAnnualNumber = payload;
    },
  },
  extraReducers: (builder) => {
    // getUserAccount
    builder.addCase(getUserAccount.fulfilled, (state, { payload }) => {
      state.user = payload.data.entity;
    });
  },
});

export const { setselectedAnnualNumber } = userSlice.actions;

export default userSlice.reducer;
