import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Description, ListProps, PhotoPreview } from '@/components';
import { awardSelectedState } from '@/redux/awards/selectors';

import { list, listCandidacy } from './constants';

import type { ITabProps } from '../types';

const PropositionTab = ({ candidacy }: ITabProps) => {
  const { selected } = useSelector(awardSelectedState);
  const { t } = useTranslation();

  const questions = useMemo(() => {
    return Array.from({ length: 10 }, (_, index) => ({
      question: selected?.[`description${index + 1}Label`] ?? null,
      answer: candidacy?.[`description${index + 1}`] ?? null,
    })).filter(({ question, answer }) => question && answer);
  }, [candidacy, selected]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} display="flex" justifyContent={{ xs: 'center', md: 'initial' }}>
            <PhotoPreview
              images={[candidacy?.featuredImageMedia?.largeThumbUrl]}
              width={267}
              height={178}
              alt={candidacy?.name}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            {candidacy?.name && <Typography variant="h4">{candidacy?.name}</Typography>}
            <ListProps dataSource={candidacy.goods[0]} list={list(t)} />
            <ListProps dataSource={candidacy} list={listCandidacy} />
          </Grid>
          <Grid item xs={12}>
            <Description text={candidacy?.description1} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        {questions.map(({ question, answer }, index) => (
          <Fragment key={index}>
            <Typography fontWeight="bold" component="h6">
              № {index + 1} {question}
            </Typography>
            <Typography
              component="div"
              color="text.secondary"
              dangerouslySetInnerHTML={{ __html: answer }}
            />
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export default PropositionTab;
