import { gql } from '@apollo/client';

export const MediaBaseFragment = gql`
  fragment MediaBaseFragment on Media {
    id
    name
    caption
    origFileName
    origUrl
    smallThumbUrl
    mediumThumbUrl
    largeThumbUrl
    type
  }
`;
