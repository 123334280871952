import { drawerWidth } from '@/components/layouts/MainLayout/constants';
import { theme } from '@/constants/theme';

export const styles = {
  list: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  drawer: (open: boolean) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      '& .MuiDrawer-paper': {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    }),
    ...(!open && {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: `calc(${theme.spacing(5)} + 1px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
      },
      '& .MuiDrawer-paper': {
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: `calc(${theme.spacing(5)} + 1px)`,
        [theme.breakpoints.up('sm')]: {
          width: `calc(${theme.spacing(8)} + 1px)`,
        },
      },
    }),
  }),
  link: { color: theme.palette.text.primary, textDecoration: 'none' },
  itemButton: (open: boolean) => ({
    minHeight: { sm: 48, xs: 24 },
    justifyContent: open ? 'initial' : 'center',
    alignItems: 'center',
    display: 'flex',
    px: {
      sm: 2.5,
      xs: 1.4,
    },
    py: {
      sm: 1,
      xs: 0.5,
    },
  }),
  itemIcon: (open: boolean) => ({
    minWidth: 0,
    mr: {
      sm: open ? 3 : 'auto',
      xs: open ? 1 : 'auto',
    },
    justifyContent: 'center',
  }),
  itemText: (open: boolean) => ({
    opacity: open ? 1 : 0,
    '& .MuiTypography-root': {
      fontSize: {
        sm: '1rem',
        xs: '0.8rem',
      },
    },
  }),
  icon: {
    fontSize: {
      sm: 24,
      xs: 18,
    },
  },
} as const;
