import { createAsyncThunk } from '@reduxjs/toolkit';

import { awardsService } from '@/services';
import { handleThunkApiError } from '@/utils';

export const createAwards = createAsyncThunk(
  'purchasing/createAwards',
  handleThunkApiError(awardsService.createAward),
);

export const updateAwards = createAsyncThunk(
  'purchasing/updateAward',
  handleThunkApiError(awardsService.updateAward),
);
