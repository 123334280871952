import { gql } from '@apollo/client';

export const GoodShortFragment = gql`
  fragment GoodShortFragment on Good {
    id
    slug
    name
    localId
    shelfLifeLength
    featuredImageMedia {
      id
      name
      smallThumbUrl
      mediumThumbUrl
    }
    Brand {
      id
      name
      Company {
        id
        name
      }
    }
  }
`;
