import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { Field, Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';

import { TextInput } from '@/components/ui/inputs';

import { schema } from './schema';
import useNoteForm from './useNoteForm';

import type { INoteProps } from './types';

const NoteForm = ({ value, title, label, btnText, onSubmit }: INoteProps) => {
  const { t } = useTranslation();
  const { handlerSubmit } = useNoteForm({ onSubmit });
  return (
    <Formik
      initialValues={{ text: value ?? '' }}
      enableReinitialize
      validationSchema={schema}
      onSubmit={handlerSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid container spacing={1}>
            {title && (
              <Grid item xs={12}>
                <Typography textAlign="center" component="h4" variant="h5">
                  {t(title)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Field
                multiline
                maxRows={4}
                minRows={3}
                fullWidth
                as={TextInput}
                label={t(label)}
                name="text"
                disabled={isSubmitting}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button type="submit" disabled={isSubmitting} variant="contained">
                {t(btnText ?? 'Send')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default NoteForm;
