import { theme } from '@/constants/theme';

export const styles = {
  button: (disable: boolean, next?: boolean) => ({
    textTransform: 'initial',
    fontWeight: 'bold',
    justifyContent: next ? 'flex-end' : 'flex-start',
    color: theme.palette.text.secondary,
    '&:hover': {
      backgroundColor: 'transparent',
    },
    ...(disable
      ? {
          visibility: 'hidden',
          opacity: 0,
        }
      : {}),
  }),
  btnTextWrapper: {
    position: 'relative',
    flexGrow: 1,
    minHeight: 27,
  },
  btnText: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
} as const;
