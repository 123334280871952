import Box from '@mui/material/Box';

import Image from '@/assets/images/logo.png';
import { combineSx } from '@/utils';

import { styles } from './styles';

import type { SxProps } from '@mui/material';
const AppLogo = ({ sx }: { sx?: Omit<SxProps, 'background'> }) => {
  return <Box sx={combineSx(styles.root(Image), sx)} />;
};

export default AppLogo;
