import { theme } from '@/constants/theme';

export const styles = {
  main: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
} as const;
