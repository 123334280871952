import { gql } from '@apollo/client';

import { CandidacyRatingFullFragment } from '@/qraphql/fragments/CandidacyRatingFullFragment';
import { client } from '@/utils';

import type {
  ICandidacyRatingCreateRequest,
  ICandidacyRatingResponse,
  IEntityResponse,
} from '@/dto';

const ratingCreate = async (values: ICandidacyRatingCreateRequest) => {
  return client.mutate<IEntityResponse<ICandidacyRatingResponse>>({
    mutation: gql`
      mutation CandidacyRatingCreateMutation($values: CandidacyRatingCreateInput!) {
        entity: candidacyRatingCreate(values: $values) {
          ...CandidacyRatingFullFragment
        }
      }
      ${CandidacyRatingFullFragment}
    `,
    variables: { values },
  });
};

const ratingUpdate = async ({
  id,
  changes,
}: {
  id: string;
  changes: ICandidacyRatingCreateRequest;
}) => {
  return client.mutate<IEntityResponse<ICandidacyRatingResponse>>({
    mutation: gql`
      mutation CandidacyRatingPatchMutation($id: ID!, $changes: CandidacyRatingPatchInput!) {
        entity: candidacyRatingPatch(id: $id, changes: $changes) {
          ...CandidacyRatingFullFragment
        }
      }
      ${CandidacyRatingFullFragment}
    `,
    variables: { id, changes },
  });
};

const candidacyService = {
  ratingCreate,
  ratingUpdate,
};
export default candidacyService;
