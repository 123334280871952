import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  awardsService,
  categoryService,
  candidacyService,
  goodsService,
  questionService,
  userNoteService,
} from '@/services';
import { handleThunkApiError } from '@/utils';

export const getAwards = createAsyncThunk(
  'awards/getAwards',
  handleThunkApiError(awardsService.getAwards),
);

export const getAward = createAsyncThunk(
  'awards/getAward',
  handleThunkApiError(awardsService.getAward),
);

export const getListOfRelatedProducts = createAsyncThunk(
  'awards/getCandidacyListOfRelated',
  handleThunkApiError(goodsService.getListOfRelatedProducts),
);

export const createResultAward = createAsyncThunk(
  'awards/createResultAward',
  handleThunkApiError(awardsService.resultCreateAward),
);

export const createAwardCategory = createAsyncThunk(
  'awards/createAwardCategory',
  handleThunkApiError(categoryService.createCategory),
);

export const createCandidacyRating = createAsyncThunk(
  'awards/createCandidacyRating',
  handleThunkApiError(candidacyService.ratingCreate),
);

export const updateCandidacyRating = createAsyncThunk(
  'awards/updateCandidacyRating',
  handleThunkApiError(candidacyService.ratingUpdate),
);

export const askQuestionCreate = createAsyncThunk(
  'awards/askQuestion',
  handleThunkApiError(questionService.createQuestion),
);

export const userNoteCreate = createAsyncThunk(
  'awards/noteCreate',
  handleThunkApiError(userNoteService.createUserNote),
);

export const updateUserNote = createAsyncThunk(
  'awards/updateUserNoteAboutCandidacy',
  handleThunkApiError(userNoteService.patchUserNote),
);

export const refusalAwardCreate = createAsyncThunk(
  'awards/refuseAward',
  handleThunkApiError(awardsService.refusalAwardCreate),
);

export const refusalAwardUpdate = createAsyncThunk(
  'awards/refuseAwardUpdate',
  handleThunkApiError(awardsService.refusalAwardUpdateCreate),
);
