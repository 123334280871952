import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { CategoryElement } from '@/components/ui/lists/components';
import { editorialUtil } from '@/utils';

import type { IListType } from '@/components/ui/lists/ListProps/types';
import type { ICandidacyResponse } from '@/dto';

export const listOfRows = (shouldTrackImage = false): IListType<ICandidacyResponse>[] => [
  {
    label: 'Products Categories',
    format: (value: ICandidacyResponse) => (
      <CategoryElement
        shouldTrackImage={shouldTrackImage}
        editorial={editorialUtil.parse(value.Company.editorialSections)}
      />
    ),
    rowSx: { display: 'flex', alignItems: 'center', mb: 1, flexWrap: 'wrap' },
  },
];

export const list: IListType<ICandidacyResponse>[] = [
  {
    label: 'Certifications',
    format: (value: ICandidacyResponse) =>
      value?.Company?.companyCertifications?.map(({ name }) => name).join(', ') ?? null,
  },
  {
    label: 'Private Label Manufacturer',
    format: (value: ICandidacyResponse) => (
      <Typography component="span" color="error">
        {value?.Company.plProducer ? 'Yes' : 'No'}
      </Typography>
    ),
  },
  {
    label: 'Company Name',
    format: (value: ICandidacyResponse) => value?.Company?.name,
  },
  {
    label: 'Address',
    format: (value: ICandidacyResponse) =>
      (value?.Company?.billingBranch?.city ?? '') +
      ',' +
      (value?.Company?.billingBranch?.address1 ?? ''),
  },
  {
    label: 'Website',
    format: (value: ICandidacyResponse) =>
      value?.Company?.website ? (
        <Link href={value?.Company?.website} target="_balnk">
          {value?.Company?.website}
        </Link>
      ) : null,
  },
];
