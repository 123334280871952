export enum AwardGoodUnit {
  GRAMS = 'GRAMS',
  MILLILITER = 'MILLILITER',
  MILLIGRAMS = 'MILLIGRAMS',
}

export enum AwardGoodUnitExternal {
  FLUID_OUNCE = 'FLUID_OUNCE',
  CUP = 'CUP',
  PINT = 'PINT',
  QUART = 'QUART',
  GALLON = 'GALLON ',
  OUNCE = 'OUNCE',
  POUND = 'POUND',
}
