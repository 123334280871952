import { gql } from '@apollo/client';

export const CandidacyRefusalFullFragment = gql`
  fragment CandidacyRefusalFullFragment on CandidacyRefusal {
    id
    localId
    Candidacy {
      id
      __typename
    }
    refusalTypes
    other
    __typename
  }
`;
