import { ImageLoader } from '@/components';

import styles from '../styles.module.scss';

import type { IPdfPageProps } from '../types';

const RelatedProductsPage = ({ candidacy }: IPdfPageProps) => {
  return (
    <div className={[styles.page, styles.endPage].join(' ')} data-title="RelatedProducts">
      <div className={styles.row}>
        <div className={[styles.col12, styles.header].join(' ')}>
          <h1 className={[styles.textCenter, styles.textMute, styles['bt-3']].join(' ')}>
            Related Products
          </h1>
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col12}>
          <div className={[styles.text, styles['mt-3']].join(' ')}>
            <table className={styles.table}>
              <thead>
                <tr>
                  <th>Image</th>
                  <th>Product Name</th>
                  <th>Range</th>
                  <th># of SKUS</th>
                  <th>Price</th>
                  <th>Shelf Life</th>
                </tr>
              </thead>
              <tbody>
                {candidacy?.goods?.map((good, index) => (
                  <tr key={index}>
                    <td>
                      <ImageLoader
                        className={styles.image}
                        alt={candidacy?.name}
                        src={good?.featuredImageMedia?.mediumThumbUrl} // good?.featuredImageMedia?.origUrl
                      />
                      {}
                    </td>
                    <td>{good?.name}</td>
                    <td>{good?.range}</td>
                    <td></td>
                    <td>Price</td>
                    <td>{good?.shelfLifeLength}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelatedProductsPage;
