import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import EmptyToolbar from '@/components/layouts/MainLayout/components/EmptyToolbar';
import { theme } from '@/constants/theme';
import { isMenuOpenState } from '@/redux/app/selectors';
import { toggleMenu } from '@/redux/app/slice';
import { combineSx } from '@/utils';

import { menuList } from './constants';
import { styles } from './styles';

const Menu = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const open = useSelector(isMenuOpenState);

  const handlerToggleMenu = useCallback(() => {
    dispatch(toggleMenu());
  }, [dispatch]);

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <Drawer
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={open}
      sx={styles.drawer(open)}
      onClose={handlerToggleMenu}
    >
      <EmptyToolbar />
      <List sx={styles.list}>
        {menuList(t).map(({ title, icon, path, sx, ...rest }, index) => (
          <ListItem key={index} disablePadding sx={combineSx({ display: 'block' }, sx)}>
            <Box component={Link} sx={styles.link} to={path} {...rest}>
              <ListItemButton
                sx={styles.itemButton(open)}
                onClick={isDesktop ? undefined : handlerToggleMenu}
              >
                <ListItemIcon sx={styles.itemIcon(open)}>{icon}</ListItemIcon>
                <ListItemText primary={title} sx={styles.itemText(open)} />
              </ListItemButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Menu;
