import EditIcon from '@mui/icons-material/Edit';
import { Button, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { styles } from './styles';

const CreateRequestCard = () => {
  const { t } = useTranslation();

  return (
    <Paper sx={styles.root} elevation={2}>
      <Typography sx={{ mb: 1 }} component="h1" variant="h4">
        {t('Create Request')}
      </Typography>
      <Typography variant="body1">{t('Choose Preferences')}</Typography>
      <Button
        component="a"
        sx={{ mt: 3 }}
        href="/purchasing-request/create"
        size="large"
        variant="contained"
        endIcon={<EditIcon />}
      >
        {t('Create Request Action')}
      </Button>
    </Paper>
  );
};

export default CreateRequestCard;
