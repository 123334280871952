import { theme } from '@/constants/theme';

export const styles = {
  root: (src: string) => ({
    display: 'flex',
    height: 'auto',
    minHeight: theme.spacing(5),
    minWidth: theme.spacing(15),
    alignItems: 'center',
    justifyContent: 'center',
    background: `center / contain no-repeat url(${src})`,
  }),
} as const;
