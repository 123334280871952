export enum EnvMode {
  DEV_ENV = 'development',
  PROD_ENV = 'production',
  TEST_ENV = 'test',
}

export interface IEnv {
  app: {
    name: string;
    backendUrl: string;
    isDev: boolean;
    isProd: boolean;
  };
  foodApi: {
    baseUrl: string;
    // eslint-disable-next-line
    targetSite: any;
    endpoint: {
      auth: string;
      tracking: string;
    };
  };
}
