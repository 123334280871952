import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import { AppLogo } from '@/components';

import { styles } from './styles';

import type { ITabProps } from '../types';

const ModalHeader = ({ candidacy }: Omit<ITabProps, 'actionsForm' | 'onChangeActionsForm'>) => {
  return (
    <DialogTitle component="div" display="flex" alignItems="center" justifyContent="space-between">
      <Box display="flex" alignItems="center" mr={1.5} flexGrow={1}>
        <AppLogo sx={styles.logo} />
        <Box sx={styles.titleWrapper}>
          <Typography variant="h5" sx={styles.title}>
            {candidacy?.name}
            {candidacy?.Company?.name && <>&nbsp;|&nbsp; {candidacy?.Company?.name}</>}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" alignItems="center">
        <Typography>Price: {candidacy?.exw_price}</Typography>
        <Typography sx={styles.moq}>MOQ: {candidacy?.moq}</Typography>
      </Box>
    </DialogTitle>
  );
};

export default ModalHeader;
