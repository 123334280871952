import { useMemo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Order } from '@/enums';
import { setSelected } from '@/redux/Goods/slice';
import { stableSort } from '@/utils';

import type { IRelatedProductsTableProps } from './types';
import type { IGoodResponse } from '@/dto';
import type { ChangeEvent } from 'react';

const useRelatedProductsTable = ({ goods }: IRelatedProductsTableProps) => {
  const [order, setOrder] = useState<Order>(Order.Asc);
  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const dispatch = useDispatch();

  const handleSort = useCallback(
    (property: string) => {
      const isAsc = orderBy === property && order === Order.Asc;
      setOrder(isAsc ? Order.Desc : Order.Asc);
      setOrderBy(property);
    },
    [order, orderBy],
  );

  const handleChangePage = useCallback((_event: unknown, newPage: number) => {
    setPage(newPage);
  }, []);

  const handleChangeRowsPerPage = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  }, []);

  const rows = useMemo(
    () =>
      stableSort(goods, { order, orderBy }).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [goods, order, orderBy, page, rowsPerPage],
  );

  const handleViewDetails = useCallback(
    (selected: IGoodResponse) => () => dispatch(setSelected(selected)),
    [dispatch],
  );

  return {
    order,
    orderBy,
    rows,
    rowsPerPage,
    page,
    handleChangeRowsPerPage,
    handleChangePage,
    handleSort,
    handleViewDetails,
  };
};

export default useRelatedProductsTable;
