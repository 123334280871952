import { getUserData, isLogged, logout } from '@food/auth';
import Box from '@mui/material/Box';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UserRole } from '@/enums';

import { EmptyToolbar, Header, Menu } from './components';

import type { PropsWithChildren } from 'react';
const MainLayout = ({ children }: PropsWithChildren<{}>) => {
  if (!isLogged()) {
    return <Redirect to="/login?traditional=true" />;
  }

  const userData = getUserData();
  if (userData.role != UserRole.Buyer) {
    logout();
    toast.error('Please use the Role of a Buyer');
    return <Redirect to="/login?traditional=true" />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Header />
      <Menu />

      <Box component="main" sx={{ flexGrow: 1 }}>
        <EmptyToolbar />
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
