import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, CircularProgress, FormHelperText, IconButton } from '@mui/material';
import { Form, Formik } from 'formik';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { createMedia } from '@/redux/media';
import { retailerUpdateMedia } from '@/redux/retailer';
import { usersRetailerState } from '@/redux/user/selectors';
import { getUserAccount } from '@/redux/user/thunks';

import BaseModal from '../BaseModal';

import { schema } from './schema';
import { styles } from './styles';

import type { IUploadLogoModalProps } from './types';
import type { AppDispatch } from '@/redux/store';

const UploadLogoModal = (props: IUploadLogoModalProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch<AppDispatch>();
  const retailer = useSelector(usersRetailerState);

  const onSubmit = useCallback(
    async (values: { file: File }) => {
      const res = await dispatch(createMedia(values)).unwrap();
      await dispatch(
        retailerUpdateMedia({
          id: retailer.id,
          changes: { logoMedia: { id: res.data.entity.id } },
        }),
      ).unwrap();

      await dispatch(getUserAccount({})).unwrap();
      toast.success('Media has been updated');
      props.handleClose();
    },
    [dispatch, props, retailer?.id],
  );

  return (
    <BaseModal {...props}>
      <Formik initialValues={{ file: null }} validationSchema={schema} onSubmit={onSubmit}>
        {({ isSubmitting, values, setFieldValue, errors }) => (
          <Form>
            <Box sx={styles.root} display="flex" flexDirection="column">
              <label htmlFor="uploadLogo">Click to upload Logo</label>
              <input
                id="uploadLogo"
                type="file"
                name="file"
                onChange={(event) => {
                  setFieldValue('file', event.currentTarget.files[0]);
                }}
              />
              {errors.file && <FormHelperText sx={{ color: 'red' }}>{errors.file}</FormHelperText>}
              {values.file && (
                <Box
                  component="img"
                  src={URL.createObjectURL(values.file)}
                  width="300px"
                  height="auto"
                />
              )}
              <Box gap={2} mt={2} display="flex">
                <Button variant="outlined" onClick={props.handleClose} disabled={isSubmitting}>
                  {t('Cancel')}
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} />}
                >
                  {t('Submit Changes')}
                </Button>
              </Box>
            </Box>
          </Form>
        )}
      </Formik>

      <IconButton onClick={props.handleClose} sx={{ position: 'absolute', right: 3, top: 3 }}>
        <CloseIcon />
      </IconButton>
    </BaseModal>
  );
};

export default UploadLogoModal;
