import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usersRetailerState } from '@/redux/user/selectors';
import { setselectedAnnualNumber } from '@/redux/user/slice';

import type { IselectedAnnualNumber } from '@/redux/user/types';

const useAnnualNumberList = () => {
  const retailer = useSelector(usersRetailerState);
  const dispatch = useDispatch();
  const userYears = useMemo(
    () => retailer?.companyYears?.filter((a) => a.year >= new Date().getFullYear() - 1),
    [retailer],
  );

  const headerItems = ['Year', 'Revenue', 'Employees', 'Action'];
  const cellItems = ['year', 'revenue', 'numEmployees'];

  const canCreateNew = useMemo(() => {
    const date = [new Date().getFullYear() - 1, new Date().getFullYear()].filter(
      (item) => !userYears.some((annual) => annual.year == item),
    );

    return date;
  }, [userYears]);

  const onSelectAction =
    ({ id = '', revenue = '', numEmployees = '', year }: IselectedAnnualNumber) =>
    () => {
      dispatch(setselectedAnnualNumber({ id, revenue, numEmployees, year }));
    };

  return { headerItems, userYears, cellItems, onSelectAction, canCreateNew };
};

export default useAnnualNumberList;
