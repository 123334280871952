import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectedAnnualNumberState } from '@/redux/user/selectors';
import { setselectedAnnualNumber } from '@/redux/user/slice';

import { UpdateNumbersForm } from '../../forms';
import BaseModal from '../BaseModal';

import AnnualNumberList from './components/AnnualNumberList';

import type { IUpdateNumbersModalProps } from './types';

const UpdateNumbersModal = (props: IUpdateNumbersModalProps) => {
  const selectedYear = useSelector(selectedAnnualNumberState);
  const dispatch = useDispatch();
  const onCancel = useCallback(() => dispatch(setselectedAnnualNumber(null)), [dispatch]);

  return (
    <BaseModal {...props}>
      {!selectedYear ? (
        <AnnualNumberList onCancel={props.handleClose} />
      ) : (
        <UpdateNumbersForm onSubmit={props.handleClose} onCancel={onCancel} />
      )}
    </BaseModal>
  );
};

export default UpdateNumbersModal;
