import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import { styles } from './styles';

const PageProgress = () => {
  return (
    <Box sx={styles.root}>
      <CircularProgress />
    </Box>
  );
};

export default PageProgress;
