import { ImageCell, RatingCell, LinkCell } from './components';

import type { ICandidacyResponse } from '@/dto';
import type { TableCellProps } from '@mui/material';

interface ICell<T> {
  key: string;
  label: string;
  th?: TableCellProps;
  td?: TableCellProps;
  sort?: boolean;
  align?: TableCellProps['align'];
  format?: (value: T) => JSX.Element;
}

export const cells: ICell<ICandidacyResponse>[] = [
  {
    label: 'Image',
    key: 'featuredImageMedia.mediumThumbUrl',
    align: 'center',
    format: (value) => <ImageCell src={value?.featuredImageMedia?.mediumThumbUrl} />,
  },
  {
    label: 'Company',
    key: 'Company.name',
    align: 'center',
    sort: true,
    format: (value) => <LinkCell label={value.Company?.name} id={value.id} />,
  },
  {
    label: 'Proposition',
    key: 'name',
    sort: true,
    align: 'center',
    format: (value) => <LinkCell label={value?.name} id={value.id} />,
  },
  {
    label: 'Related Products',
    // key: 'related.length',
    key: 'goods.length',
    sort: true,
    align: 'center',
  },
  {
    label: 'Price',
    key: 'exw_price',
    sort: true,
    align: 'center',
  },
  {
    label: 'MOQ',
    key: 'moq',
    sort: true,
    align: 'center',
  },
  {
    label: 'Rating',
    key: 'rating',
    sort: true,
    align: 'center',
    format: (value) => <RatingCell id={value.id} value={value.rating} />,
  },
];
